import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/index.js'
import Login from '@/components/Login.vue'
import SSOLogin from '@/components/SSOLogin.vue'
import Support from '@/components/support/FAQ.vue'
import i18n from '@/i18n'
import Admin from '@/shared/components/Admin.vue'
import AppDashboard from '@/components/AppDashboard'
import Dashboard from '@/components/Dashboard'

import valuationRoutes from '@/apps/Valuation/routes'
import ersRoutes from '@/apps/ERS/routes'

import utils from '@/shared/plugins/utils'
import axios from '@/shared/plugins/axios'

Vue.use(Router)
const langs = ['nl-BE', 'fr-BE', 'en-BE', 'technical']

function prefixRoutes(prefix, routes) {
    for (const route of routes) {
        route.path = prefix + route.path
    }
}

export function createRouter(conf) {
    const beforeEach = async (to, from, next) => {
        const prevLang = localStorage.getItem('lang') || i18n.locale || 'en-BE'

        if (!to.params.lang) {
            return next({
                name: to.name,
                params: { ...to.params, lang: prevLang },
                query: to.query,
            })
        }

        // update the lang in the store
        localStorage.setItem('lang', to.params.lang)
        i18n.locale = to.params.lang

        const actionList = [
            'start',
            'enter',
            'building-type',
            'address',
            'confirm-main-building',
            'select-main-building',
            'select-parcels',
            'confirm-main-parcel',
            'select-main-parcel',
            'select-extra-parcels',
            'view-building-parcels',
            'extra-info',
            'renovation-info',
            'feedback',
            'faq',
            'edit',
            'ovm',
        ]

        if (to.name.startsWith('ers.') && conf.TRACK) {
            axios.post(utils.urlJoin(conf.VALUATION_API_URL, '/track'), {
                route: {
                    to: {
                        fullPath: to.fullPath,
                        query: to.query,
                        params: to.params,
                        path: to.path,
                        name: to.name,
                    },
                    from: {
                        fullPath: from.fullPath,
                        query: from.query,
                        params: from.params,
                        path: from.path,
                        name: from.name,
                    },
                },
            })
        }

        if (to.name.startsWith('ers.')) {
            if (to.name === 'ers.result' && from.name !== 'ers.result') {
                if (!from.matched.find((route) => route.name === 'ers-questionnaire')) {
                    // reset the store only if the last route parent is outside questionnaire
                    store.commit('ersStore/RESET_STORE')
                }
            }

            if (to.query.notification_ref) {
                // TODO: allow using a notification ref instead of a [valuation_request_]ref
            } else if (
                to.query.ref &&
                store.state.ersStore.request_reference !== to.query.ref && // store.state.ersStore.request_reference !== to.query.ref  is always true if to.query.ref is truthy and store.state.ersStore.request_reference is falsy
                !store.state.ersStore.request_reference
            ) {
                store.dispatch('ersStore/fetch_features', to.query.ref).then((res) => {
                    if (to.name === 'processing') {
                        // TODO: fix: if to.name starts with ers., it can't be "processing"
                        store.dispatch('ersStore/fetch_map_scene3d', true)
                    }
                })
            } else if (to.name === 'processing') {
                // TODO: fix: if to.name starts with ers., it can't be "processing"
                store.dispatch('ersStore/fetch_map_scene3d', true)
            } else if (to.name === 'building') {
                // TODO: fix: if to.name starts with ers., it can't be "building"
                store.dispatch('ersStore/fetch_map_scene3d', false)
                store.dispatch('ersStore/fetch_building_features')
            }

            return next()
        }

        const action_exists = actionList.includes(to.params.action)
        const check_login = conf.CHECK_LOGIN && to.name !== 'valuation.report'
        if (check_login && store.state.auth.authenticated == null) {
            await store.dispatch('auth/check_authentication')
            if (
                store.state.auth.authenticated &&
                process.env.NODE_ENV.toLowerCase() !== 'development'
            ) {
                store.dispatch('auth/start_interval')
            }
        }
        if (check_login && to.name === 'login' && store.state.auth.authenticated) {
            next({
                name: 'valuation.request',
                params: { lang: to.params.lang, action: 'start' },
            })
        } else if (check_login && !store.state.auth.authenticated) {
            let org_hint = utils.getCookie('re-org-hint')
            let redirect_query_param = to.query.r ? `&r=${to.query.r}` : ''
            if (to.name === 'login' && to.query.msg) {
                next()
            } else if (
                conf.ROCKESTATE_SSO_ENABLED &&
                conf.ROCKESTATE_SSO_URL &&
                org_hint === 'rockestate'
            ) {
                window.location.href = conf.ROCKESTATE_SSO_URL + redirect_query_param
            } else if (conf.CUSTOMER_SSO_ENABLED && conf.CUSTOMER_SSO_URL) {
                window.location.href = conf.CUSTOMER_SSO_URL + redirect_query_param
            } else if (conf.UNAUTHORIZED_URL) {
                window.location.href = conf.UNAUTHORIZED_URL
            } else if (to.name !== 'login') {
                next({ name: 'login', params: { lang: to.params.lang } })
            } else {
                next()
            }
        } else if (to.name === 'dashboard') {
            store.dispatch('valuationStore/Address/reset_store').then((res) => {
                next()
            })
        } else if (to.name === 'valuation.request' && !action_exists) {
            next({
                name: 'valuation.request',
                params: { lang: to.params.lang, action: 'start' },
            })
        } else if (to.name === 'support' && from.query.valuation_request_ref) {
            to.query.valuation_request_ref = from.query.valuation_request_ref
            next()
        } else if (to.name === 'valuation.dvm') {
            if (
                !conf.ENABLE_DVM ||
                (check_login && !store.getters['auth/hasRole']('valuer', 'dvm'))
            ) {
                next({ name: 'dashboard', params: { lang: to.params.lang } })
            }

            store.commit('valuationStore/Address/RESET_INTERNAL_REMARKS')
            store.commit('valuationStore/Address/RESET_VALUER_REMARKS')
            store.commit('valuationStore/RESET_VAL')
            if (typeof to.query.valuation_request_ref !== 'undefined') {
                store.commit(
                    'valuationStore/SET_VALUATION_REQUEST_REF',
                    to.query.valuation_request_ref
                )
                await store.dispatch('valuationStore/load_valuation_request')
            }
            next()
        } else if (
            to.name === 'admin' &&
            check_login &&
            !store.getters['auth/hasRole']('admin')
        ) {
            next({ name: 'dashboard', params: { lang: to.params.lang } })
        } else if (to.name === 'valuation.edit') {
            if (typeof to.query.valuation_request_ref !== 'undefined') {
                store.commit(
                    'valuationStore/SET_VALUATION_REQUEST_REF',
                    to.query.valuation_request_ref
                )
                await store.dispatch('valuationStore/load_valuation_request')
            }
            let action = 'extra-info'
            if (!store.state.valuationStore.Address.features.f_building_type) {
                action = 'building-type'
            } else if (
                ['apartment', 'house'].includes(
                    store.state.valuationStore.Address.features.f_building_type
                )
            ) {
                if (!store.state.valuationStore.Address.address.streetname) {
                    action = 'address'
                } else if (!store.state.valuationStore.Address.features.building_id) {
                    await store.dispatch('valuationStore/Address/match_address')
                    action = 'confirm-main-building'
                }
            }

            next({
                name: 'valuation.request',
                params: { lang: to.params.lang, action },
                query: to.query,
            })
        } else {
            if (typeof to.query.valuation_request_ref !== 'undefined') {
                if (
                    store.state.valuationStore.Address.isLoaded &&
                    to.query.valuation_request_ref ===
                        store.state.valuationStore.valuation_request_ref
                ) {
                    next()
                } else {
                    store.commit(
                        'valuationStore/SET_VALUATION_REQUEST_REF',
                        to.query.valuation_request_ref
                    )
                    await store.dispatch('valuationStore/load_valuation_request')
                }
            }
            next()
        }
    }

    const routes = [
        {
            path: '/',
            ...(conf.ENABLED_APPS.length > 1
                ? {
                      name: 'appDashboard',
                      component: AppDashboard,
                  }
                : {
                      redirect: {
                          name: conf.ENABLED_APPS[0],
                      },
                  }),
        },
        {
            path: '/default',
            redirect: '/', // This will set lang to undefined which will trigger the prevLang logic
        },
        {
            path: '/default/:route',
            redirect: `/:route`,
        },
        {
            path: '/be-EN',
            redirect: '/en-BE', // This is needed because the next one expects a route (doesn't catch /be-EN/)
        },
        {
            path: '/be-EN/:route',
            redirect: '/en-BE/:route',
        },
        {
            path: '/be-NL',
            redirect: '/nl-BE',
        },
        {
            path: '/be-NL/:route',
            redirect: '/nl-BE/:route',
        },
        {
            path: '/be-FR',
            redirect: '/fr-BE',
        },
        {
            path: '/be-FR/:route',
            redirect: '/fr-BE/:route',
        },
        {
            name: 'login',
            component: conf.CUSTOMER_SSO_ENABLED ? SSOLogin : Login,
            path: '/login',
        },
        {
            name: 'support',
            component: Support,
            path: '/support',
        },
        {
            name: 'admin',
            component: Admin,
            path: '/admin',
        },
        {
            name: 'dashboard',
            component: Dashboard,
            path: '/dashboard',
        },
        ...(conf.ENABLED_APPS.includes('valuation') ? valuationRoutes : []),
        ...(conf.ENABLED_APPS.includes('ers') ? ersRoutes : []),
        {
            path: '/*',
            redirect: '/',
        },
    ]

    // With this we make the lang param can only contain those in langs
    // Since `/:lang/` could also match `/dashboard/`
    const langRegex = langs.join('||')
    prefixRoutes(`/:lang(${langRegex})?`, routes)

    const router = new Router({
        mode: 'history',
        base: conf.FRONTEND_URL,
        routes: routes,
        scrollBehavior(to, from, savedPosition) {
            if (to.name === 'report') {
                return
            }
            if (to.name === from.name) {
                return
            }
            if (to.hash) {
                return {
                    selector: to.hash,
                    behavior: 'smooth',
                }
            }
            return { x: 0, y: 0 }
        },
    })

    router.beforeEach(beforeEach)

    return router
}

// what if switch to this type of structure in routes?
// if so it'll be possible to put <router-view/> into Valuation.vue and let Vue do do rest not importing all pages
// https://github.com/Rome00/kockta-mitarbi-master/blob/master/src/router/index.js
