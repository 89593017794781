<template>
    <div class="question-container">
        <div class="question" @click="toggleAnswer">
            <h2
                class="has-text-black is-size-5 has-text-weight-medium is-flex is-align-items-center"
            >
                {{ qData.question }}
            </h2>
            <span class="question-icons is-align-items-riight">
                <svg-icon v-for="m of qData.modules" :key="m" :icon="m" scale="1" />
                <b-icon
                    :icon="answer_shown ? 'minus' : 'plus'"
                    class="is-medium"
                    type="is-primary"
                />
            </span>
        </div>
        <vue-markdown v-if="answer_shown" :source="qData.answer" class="answer" />
    </div>
</template>
<script>
import VueMarkdown from 'vue-markdown-render'
import utils from '@/shared/plugins/utils'

export default {
    components: {
        VueMarkdown,
    },
    props: {
        open: {
            type: Boolean,
            required: false,
            default: false,
        },
        qData: {
            type: Object,
            required: true,
        },
    },
    data() {
        return { answer_shown: this.open }
    },
    watch: {
        open(val) {
            this.answer_shown = val
        },
    },
    methods: {
        toggleAnswer() {
            this.answer_shown = !this.answer_shown
        },
    },
}
</script>
<style lang="scss">
.question-container {
    &:not(:last-child) {
        border-bottom: 1px solid #d6e2e9;
    }
}

.question {
    cursor: pointer;
    padding: 15px 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    img {
        max-width: 18px;
    }
}

.question-icons {
    width: 64px;
    flex-shrink: 0;
}

.answer {
    background: #d6e2e933;
    border-top: 1px solid #d6e2e9;
    padding: 15px 30px;
    color: #001837;

    & > ul {
        list-style: disc inside !important;
        font-weight: bold;
        margin-top: 1rem;
    }
}
</style>
