<template>
    <div>
        <h2 class="title is-size-4 has-text-weight-bold mgt-medium pb-4">
            Availability management
        </h2>
        <b-tabs v-model="activeTab" type="is-boxed">
            <b-tab-item v-for="r in rules" :key="r.id" :label="r.name" :value="r.id">
                <template #header>
                    <span>{{ r.name }}</span>
                    <button class="delete-button" @click.stop="deleteRule(r.id)">
                        <b-icon icon="trash-can" />
                    </button>
                </template>
                <availability-rule
                    :rule="r"
                    :nuts3="nuts3"
                    :element-token="cronofyUser.cronofy_element_token"
                    @save="updateRule(r.id, $event)"
                />
            </b-tab-item>
            <b-tab-item key="add" icon="plus" label="Add availability slot" value="add">
                <b-progress></b-progress>
            </b-tab-item>
        </b-tabs>
    </div>
</template>

<script>
import utils from '@/shared/plugins/utils'
import AvailabilityRule from './AvailabilityRule'

export default {
    name: 'AvailabilityRules',
    components: {
        AvailabilityRule,
    },
    props: {
        cronofyUser: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        rules: [],
        activeTab: null,
        nuts3: null,
    }),
    computed: {
        rulesUrl() {
            return utils.urlJoin(this.$config.VALUATION_API_URL, [
                'availability',
                'user',
                this.cronofyUser.user_id,
                'rules',
            ])
        },
    },
    watch: {
        async activeTab(v) {
            if (v === 'add') {
                try {
                    const res = await this.createEmptyRule()
                    this.activeTab = res.id
                } catch {
                    this.activeTab = null
                }
            }
        },
    },
    async mounted() {
        await this.loadNuts3()
        await this.getRules()
        if (this.rules.length) {
            this.activeTab = this.rules[0].id
        }
    },
    methods: {
        ruleUrl(id) {
            return utils.urlJoin(this.rulesUrl, [id])
        },
        async changeActiveTab(v) {
            if (v === 'add') {
                const res = await this.createEmptyRule()
                console.log(res)
            }
        },
        async getRules() {
            const res = await this.$axios.get(this.rulesUrl)
            this.rules = res.data
        },
        async createEmptyRule() {
            const res = await this.$axios.post(this.rulesUrl, {
                regions: [],
                name: 'New rule',
            })

            if (res.status === 201) {
                await this.getRules()
            }

            return res.data
        },
        async deleteRule(id) {
            if (confirm('Are you sure you want to delete this rule?')) {
                const url = this.ruleUrl(id)
                const res = await this.$axios.delete(url)
                console.log(res)

                if (res.status === 204) {
                    await this.getRules()
                    if (this.rules.length && this.activeTab === id) {
                        this.activeTab = this.rules[0].id
                    }
                }
            }
        },
        async loadNuts3() {
            const url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                'availability',
                'nuts3',
            ])

            const res = await this.$axios.get(url)

            if (res.status === 200) {
                this.nuts3 = this.processNuts3(res.data)
            }
        },
        processNuts3(nuts3) {
            const provinceAwareNuts3 = {}

            for (const n of nuts3) {
                if (!(n.prov_en in provinceAwareNuts3)) {
                    provinceAwareNuts3[n.prov_en] = {
                        prov_en: n.prov_en,
                        prov_fr: n.prov_fr,
                        prov_nl: n.prov_nl,
                        nuts3: [],
                    }
                }
                provinceAwareNuts3[n.prov_en].nuts3.push(n)
            }

            // Manual fill for Brussels
            provinceAwareNuts3['nan'].prov_en = 'Brussels Capital Region'
            provinceAwareNuts3['nan'].prov_fr = 'Région de Bruxelles-Capitale'
            provinceAwareNuts3['nan'].prov_nl = 'Brussels Hoofdstedelijk Gewest'

            return Object.values(provinceAwareNuts3)
        },
        async updateRule(id, data) {
            console.log(id, data)
            const url = this.ruleUrl(id)
            const res = await this.$axios.put(url, data)

            if (res.status === 200) {
                await this.getRules()
            }

            return res.data
        },
    },
}
</script>

<style scoped lang="scss">
.delete-button {
    border: unset;
    background-color: unset;
    color: red;
    padding: 0;
    margin-left: 0.8rem;
    cursor: pointer;
    span.icon {
        margin-left: 0;
        margin-right: 0;
    }

    &:hover {
        color: black;
    }
}
</style>
