<template>
    <div :id="elId"></div>
</template>

<script>
export default {
    name: 'CronofyAvailability',
    props: {
        elementToken: {
            default: null,
            type: String,
        },
        ruleId: {
            required: true,
            type: String,
        },
    },
    data: () => ({
        availabilityRules: null,
    }),
    computed: {
        elId() {
            return 'cronofy-availability-' + this.ruleId
        },
        locale() {
            return this.$i18n.locale.split('-')[0]
        },
    },
    watch: {
        locale(locale) {
            if (this.availabilityRules) {
                this.availabilityRules.update({
                    locale,
                })
            }
        },
    },
    mounted() {
        this.availabilityRules = CronofyElements.AvailabilityRules({
            element_token: this.elementToken,
            target_id: this.elId,
            availability_rule_id: this.ruleId,
            data_center: 'de',
            config: {
                start_time: '08:00',
                end_time: '18:00',
                duration: 30,
                week_start_day: 'monday',
                tz_list: ['Europe/Brussels'],
                auto_create_rules: true,
                default_weekly_periods: [
                    {
                        day: 'monday',
                        start_time: '09:00',
                        end_time: '17:00',
                    },
                    {
                        day: 'tuesday',
                        start_time: '09:00',
                        end_time: '17:00',
                    },
                    {
                        day: 'wednesday',
                        start_time: '09:00',
                        end_time: '17:00',
                    },
                    {
                        day: 'thursday',
                        start_time: '09:00',
                        end_time: '17:00',
                    },
                    {
                        day: 'friday',
                        start_time: '09:00',
                        end_time: '17:00',
                    },
                ],
            },
            styles: {
                prefix: 'CronofyAvailability',
                colors: {
                    primary: '#0096ff',
                },
            },
            tzid: 'Europe/Brussels',
            callback: this.cronofyCallback,
            locale: this.locale,
        })
    },
    methods: {
        cronofyCallback(ev) {
            switch (ev.notification.type) {
                case 'availability_rule_edited':
                    this.$emit('input')
                    break
                case 'availability_rule_saved':
                    this.$emit('save')
                    break
            }
        },
    },
}
</script>

<style>
.CronofyAvailability__footer {
    flex-wrap: wrap;
}
.CronofyAvailability__submit {
    width: 100%;
}
.CronofyAvailability__timezone {
    display: none !important;
}
</style>
