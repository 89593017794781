<template>
    <b-dropdown v-if="submissionsAvailable.length > 1" aria-role="list">
        <template #trigger>
            <slot name="default"></slot>
        </template>
        <b-dropdown-item
            v-for="app in submissionsAvailable"
            :key="`appToggle_${app}`"
            aria-role="listitem"
            class="new_request-dropdown-item"
            has-link
        >
            <router-link :to="{ name: app }">
                <img :src="requestTypeIcon(app)" />
                {{ $t(`dashboard.type_toggles.${app}`) }}
            </router-link>
        </b-dropdown-item>
    </b-dropdown>
    <router-link
        v-else-if="submissionsAvailable.length === 1"
        :to="{
            name: submissionsAvailable[0],
        }"
    >
        <slot name="default"></slot>
    </router-link>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'NewRequestButton',
    computed: {
        ...mapGetters('auth', ['submissionsAvailable']),
    },
    methods: {
        requestTypeIcon(requestType) {
            // necessary for dynamic asset import because Vue adds a hash to asset names
            let imgImport = require.context(
                '@/assets/icons/request-types',
                false,
                /\.svg$/
            )
            return imgImport(`./${requestType}.svg`)
        },
    },
}
</script>
