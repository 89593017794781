<i18n lang="json">
    {
        "en-BE": {
            "PAYMENT_STATUS_TITLE": "Payment status",
            "PAYMENT_NEEDED": "Please proceed to the payment of the fee for this valuation request: €{amount_due}",
            "AMOUNT_DUE": "Amount due:",
            "PAY_BTN": "Pay €{amount_due}",
            "payment_status": {
                "PENDING": "Your payment is processing…",
                "SUCCEEDED": "Your payment for €{amount_paid} has been successfully received. Thank you!",
                "CANCELLED": "Your payment has been cancelled.",
                "REFUNDED": "Your payment has been refunded."
            }
        },
        "fr-BE": {
            "PAYMENT_NEEDED": "Veuillez régler le paiement pour cette demande",
            "AMOUNT_DUE": "Montant dû&nbsp;:"
        }
    }    
</i18n>

<template>
    <div class="container">
        <h2 class="title is-size-2 mgt-medium is-family-secondary has-text-weight-bold">
            {{ $t('PAYMENT_STATUS_TITLE') }}
        </h2>
        <div class="payment">
            <div v-if="errorMessage" class="error-message p-2 has-text-centered">
                <p>{{ errorMessage }}</p>
            </div>
            <b-loading
                :is-full-page="true"
                :active="!paymentSummary && !errorMessage"
            ></b-loading>
            <template v-if="paymentSummary">
                <p class="my-3">
                    <em>Ref: #{{ valRef }}</em>
                </p>
                <nav
                    v-if="paymentSummary.status && paymentSummary.status != 'STARTED'"
                    :class="[
                        'level',
                        'notification',
                        statusToClass(paymentSummary.status),
                    ]"
                >
                    {{
                        $t(`payment_status.${paymentSummary.status}`, {
                            amount_paid: paymentSummary.total_paid_euro,
                        })
                    }}
                    <PulseLoader v-if="paymentProcessing" color="white" />
                </nav>
                <p v-if="paymentSummary.is_payment_needed">
                    {{
                        $t('PAYMENT_NEEDED', {
                            amount_due: amountDueEuro || paymentSummary.amount_due_euro,
                        })
                    }}
                </p>
                <template v-if="stripeClientSecret">
                    <StripeElementPayment
                        ref="paymentRef"
                        class="my-2"
                        :pk="STRIPE_PUBLISHABLE_KEY"
                        :elements-options="{
                            appearance: {},
                            clientSecret: stripeClientSecret,
                        }"
                        :confirm-params="{ return_url: returnUrl }"
                    />
                </template>
                <nav-buttons
                    :next-step="!paymentSummary.is_payment_needed ? nextStep : null"
                    :prev-step="prevStep"
                >
                    <b-button v-if="stripeClientSecret" type="is-primary" @click="pay">
                        {{ $t('PAY_BTN', { amount_due: amountDueEuro }) }}
                    </b-button>
                </nav-buttons>
            </template>
        </div>
    </div>
</template>

<script>
// Dev access via /valuation/payment?valuation_request_ref=...
// Proper access via borrower flow

import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { StripeElementPayment } from '@vue-stripe/vue-stripe'
import { mapGetters } from 'vuex'
import utils from '@/shared/plugins/utils'
import NavButtons from '../NavButtons.vue'

export default {
    name: 'BorrowerPayment',
    components: {
        StripeElementPayment,
        PulseLoader,
        NavButtons,
    },
    props: {
        nextStep: {
            required: false,
            default: '',
            type: String,
        },
        prevStep: {
            required: false,
            default: '',
            type: String,
        },
    },
    data: function() {
        return {
            paymentSummary: undefined,
            amountDueEuro: undefined,
            errorMessage: undefined,
            stripeClientSecret: undefined,
            paymentProcessing: false,
            STRIPE_PUBLISHABLE_KEY: this.$config.STRIPE_PUBLISHABLE_KEY,
        }
    },
    computed: {
        ...mapGetters('valuationStore', ['ovm_status', 'getRequestRef']),
        valRef() {
            return this.$route.params.val_ref || this.getRequestRef
        },
        paymentUrl() {
            return utils.urlJoin(this.$config.VALUATION_API_URL, [
                'payment',
                'val_ref',
                this.valRef,
            ])
        },
        returnUrl() {
            return window.location.href
        },
    },
    watch: {
        paymentSummary: function() {
            this.paymentProcessing = false

            if (this.paymentSummary && this.paymentSummary.is_payment_needed) {
                if (
                    this.$route.query.redirect_status &&
                    this.paymentSummary.status == 'STARTED'
                ) {
                    // we are redirected after a Stripe payment:
                    // probably need to wait until the payment is processed in the backend
                    console.log('Waiting for backend to update payment status…')

                    this.paymentProcessing = true
                    this.paymentSummary.status = 'PENDING'
                    this.paymentSummary.is_payment_needed = false
                    setTimeout(this.load_payment_summary, 5000)
                } else {
                    this.getPaymentIntent()
                }
            }
        },
    },
    mounted() {
        this.loadPaymentSummary()
    },
    methods: {
        loadPaymentSummary() {
            this.$axios
                .get(this.paymentUrl)
                .then((response) => {
                    this.paymentSummary = response.data
                })
                .catch((error) => {
                    if (error.response.status == 404)
                        this.errorMessage = 'Valuation request not found'
                    else this.errorMessage = error.response.data
                })
        },
        getPaymentIntent() {
            this.$axios
                .post(this.paymentUrl, {})
                .then((res) => {
                    this.stripeClientSecret = res.data.client_secret
                    this.amountDueEuro = res.data.amount_euro
                })
                .catch(this.handle_error)
        },
        pay() {
            this.$refs.paymentRef.submit()
        },
        statusToClass(status) {
            if (status == 'SUCCEEDED') return 'is-success'
            if (status == 'CANCELLED') return 'is-error'
            if (status == 'REFUNDED') return 'is-warning'
            return 'is-info'
        },
    },
}
</script>

<style lang="scss" scoped>
.payment {
    max-width: 480px;
    margin: auto;
}
.error-message {
    background-color: #fdd;
    border: 1px solid #f00;
    border-radius: 4px;
}
.button-flex {
    display: flex;
    justify-content: space-between;
}
</style>
