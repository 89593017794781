<i18n lang="json">
    {
        "en-BE": {
            "appointmentStatus": {
                "PENDING_CONFIRMATION": "Waiting for expert's confirmation",
                "CONFIRMED": "The expert has confirmed the appointment"
            },
            "paymentNeeded": {
                "true": "€{amountToPay}",
                "false": "No extra payment needed"
            },
            "paymentStatus": {
                "PENDING": "The payment is processing…",
                "SUCCEEDED": "The payment has been successfully received.",
                "CANCELLED": "The payment has been cancelled.",
                "REFUNDED": "The payment has been refunded."
            }
        }
    }    
    </i18n>

<template>
    <div class="container">
        <h2 class="title is-size-2 mgt-medium is-family-secondary has-text-weight-bold">
            Appointment summary
        </h2>
        <div class="appointment-summary">
            <div v-if="!latestAppointment">
                No appointment has been scheduled for this request yet.
            </div>
            <template v-else>
                <h3 class="title is-size-3 mt-3">Appointment information</h3>
                <div>
                    <strong>Address:</strong>
                    <a :href="addressGoogleMaps" target="_blank">
                        {{ getAddress.full_address }}
                    </a>
                </div>
                <div>
                    <strong>Date:</strong>
                    <span>{{ appointmentStartTime.toLocaleString() }}</span>
                </div>
                <div>
                    <strong>Status:</strong>
                    <span>{{ $t('appointmentStatus.' + latestAppointment.status) }}</span>
                </div>
                <template v-if="borrower">
                    <h3 class="title is-size-3 mt-3">Borrower information</h3>
                    <div>
                        <strong>Name:</strong>
                        <span>{{ borrower.first_name }} {{ borrower.last_name }}</span>
                    </div>
                    <div>
                        <strong>Email:</strong>
                        <a :href="'mailto:' + borrower.email">{{ borrower.email }}</a>
                    </div>
                    <div>
                        <strong>Phone number:</strong>
                        <a :href="'tel:' + borrower.phone">{{ borrower.phone }}</a>
                    </div>
                </template>
                <template v-if="paymentSummary">
                    <h3 class="title is-size-3 mt-3">Payment information</h3>
                    <div>
                        <strong>Status:</strong>
                        <span>{{ $t('paymentStatus.' + paymentSummary.status) }}</span>
                    </div>
                    <div>
                        <strong>Amount paid:</strong>
                        <span>€{{ paymentSummary.total_paid_euro }}</span>
                    </div>
                    <div>
                        <strong>Amount due:</strong>
                        <span>
                            {{
                                $t('paymentNeeded.' + paymentSummary.is_payment_needed, {
                                    amountToPay: paymentSummary.amount_due_euro,
                                })
                            }}
                        </span>
                    </div>
                </template>
            </template>
            <nav-buttons :next-step="nextStep" :prev-step="prevStep">
                <template
                    v-if="
                        latestAppointment &&
                            latestAppointment.status === 'PENDING_CONFIRMATION'
                    "
                >
                    <b-button
                        type="is-primary"
                        :loading="loadingConfirmation"
                        @click="confirmAppointment"
                    >
                        Confirm appointment
                    </b-button>
                    <b-button :loading="loadingConfirmation" @click="cancelAppointment">
                        Cancel appointment
                    </b-button>
                </template>
            </nav-buttons>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import utils from '@/shared/plugins/utils'
import NavButtons from './NavButtons.vue'

export default {
    name: 'OvmSummary',
    components: {
        NavButtons,
    },
    props: {
        nextStep: {
            required: false,
            default: '',
            type: String,
        },
        prevStep: {
            required: false,
            default: '',
            type: String,
        },
    },
    data: () => ({
        appointments: [],
        paymentSummary: null,
        loadingConfirmation: false,
    }),
    computed: {
        ...mapGetters('valuationStore', ['getRequestRef', 'ovmInfo', 'borrower']),
        ...mapGetters('valuationStore/Address', ['getAddress']),
        latestAppointment() {
            return this.appointments.length ? this.appointments[0] : null
        },
        appointmentStatus() {
            return this.latestAppointment?.status
        },
        appointmentStartTime() {
            return new Date(this.latestAppointment?.start_time)
        },
        addressGoogleMaps() {
            const urlEncodedAddress = encodeURIComponent(this.getAddress.full_address)
            return 'https://www.google.com/maps?q=' + urlEncodedAddress
        },
    },
    mounted() {
        this.getAppointments()
        this.getPayment()
    },
    methods: {
        async getAppointments() {
            const url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                'appointment',
                'request',
                this.getRequestRef,
                'event',
            ])
            const res = await this.$axios.get(url)

            if (res.status === 200) {
                this.appointments = res.data
            }
        },
        async getPayment() {
            const url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                'payment',
                'val_ref',
                this.getRequestRef,
            ])

            const res = await this.$axios.get(url)

            if (res.status === 200) {
                this.paymentSummary = res.data
            }
        },
        async cancelAppointment() {
            this.loadingConfirmation = true
            const url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                'appointment',
                'event',
                this.latestAppointment.cronofy_event_id,
            ])

            const res = await this.$axios.put(url, {
                status: 'CANCELLED',
            })

            if (res.status === 200) {
                this.getAppointments()
            }
            this.loadingConfirmation = false
        },
        async confirmAppointment() {
            this.loadingConfirmation = true
            const url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                'appointment',
                'event',
                this.latestAppointment.cronofy_event_id,
            ])

            const res = await this.$axios.put(url, {
                status: 'CONFIRMED',
            })

            if (res.status === 200) {
                this.getAppointments()
            }
            this.loadingConfirmation = false
        },
    },
}
</script>

<style lang="scss">
.appointment-summary {
    max-width: 600px;
    margin: auto;
    strong {
        margin-right: 0.5rem;
    }
}
</style>
