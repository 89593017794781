var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidemenu",class:{ wide: _vm.isWide }},[(_vm.$config.ENABLED_APPS.length > 1)?_c('router-link',{staticClass:"sidemenu-item",class:{ active: _vm.$route.name === 'appDashboard' },attrs:{"to":{ name: 'appDashboard' }}},[_c('svg-icon',{attrs:{"icon":"apps","scale":"1"}}),_c('h3',[_vm._v(_vm._s(_vm.$t('section.apps')))])],1):_vm._e(),_c('router-link',{staticClass:"sidemenu-item",class:{ active: _vm.$route.name === 'dashboard' },attrs:{"to":{ name: 'dashboard' }}},[_c('svg-icon',{attrs:{"icon":"monitor","scale":"1"}}),_c('h3',[_vm._v(_vm._s(_vm.$t('section.dashboard')))])],1),(_vm.onsiteValuer)?_c('router-link',{staticClass:"sidemenu-item",class:{ active: _vm.$route.name === 'valuation.valuer-settings' },attrs:{"to":{ name: 'valuation.valuer-settings' }}},[_c('b-icon',{staticStyle:{"height":"32px","width":"32px"},attrs:{"icon":"account"}}),_c('h3',[_vm._v("Onsite valuer")])],1):_vm._e(),_c('router-link',{staticClass:"sidemenu-item",class:{ active: _vm.$route.name === 'support' },attrs:{"to":{
            name: 'support',
            params: {
                lang: _vm.$route.params.lang,
            },
            query: _vm.from_query_args,
        }}},[_c('svg-icon',{attrs:{"icon":"help","scale":"1"}}),_c('h3',[_vm._v(_vm._s(_vm.$t('section.helpcenter')))])],1),(_vm.hasRole('admin'))?_c('router-link',{staticClass:"sidemenu-item",class:{ active: _vm.$route.name === 'admin' },attrs:{"to":{
            name: 'admin',
            params: {
                lang: _vm.$route.params.lang,
            },
        }}},[_c('svg-icon',{attrs:{"icon":"user-admin","scale":"1"}}),_c('h3',[_vm._v(_vm._s(_vm.$t('section.user-admin')))])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }