<i18n>
{
    "en-BE": {
        "customer": "Customer",
        "actions": "Actions",
        "address": "Address",
        "type": "Type",
        "automatic_valuation": "Automatic valuation",
        "cancel": "Cancel",
        "customer_ref": "Customer ref",
        "edit": "Edit",
        "web_report": "Web report",
        "pdf_report": "PDF report",
        "downloading": "Downloading...",
        "features": {
            "f_epc": "EPC",
            "f_touching_sides": "Number of facades",
            "f_construction_year": "Construction year",
            "f_living_area": "Living area",
            "f_x": "Longitude",
            "f_y": "Latitude"
        },
        "info": "Info",
        "info_title": "More information",
        "last_modified": "Last modified",
        "created_at": "Created",
        "missing_features": "The following features are missing, preventing an automatic valuation:",
        "ok": "OK",
        "per_page": "per page",
        "request_ref": "Request ref",
        "search": "Search",
        "status_col": "Status",
        "status": {
            "awaiting-onsite": "Awaiting onsite",
            "under-dvm-valuation": "Submitted",
            "draft": "Draft",
            "submitted": "Submitted",
            "exotic": "Awaiting onsite",
            "needs-review": "More information needed",
            "cancelled": "Cancelled",
            "valued": "Valued"
        },
        "badges": {
            "resubmitted": "Resubmitted",
            "needs_on_site": "OVM Needed"
        },
        "tooltip_text": "Follow here the status of your valuation requests",
        "review_tooltip": "This request has been analyzed by a valuation specialist and some answers need to be reviewed",
        "view_request": "View request",
        "dvm": "DVM",
        "appointment_summary": "Appointment Summary",
        "lang": "Language",
        "valuer": "Valuer",
        "unassigned": "Unassigned",
        "new_request": "New Request",
        "create_report": "Generate report",
        "refresh_report": "Generate new report",
        "download_report": "Save report",
        "report_loading": "Report loading",
        "owner": "Owner",
        "take_ownership": "Take ownership",
        "sub_modal": {
            "content": "Do you confirm you want to take ownership of this request?\n The current owner will be notified of the ownership transfer.",
            "actions": {
                "cancel": "Cancel",
                "confirm": "Confirm"
            }
        },
        "valuer_change_modal": {
            "content": "This request is already assigned. Do you confirm you want to change the assigned valuer? This will prevent the previously assigned valuer from working on the request.",
            "actions": {
                "cancel": "Cancel",
                "confirm": "Confirm"
            }
        },
        "duplicates": "Duplicates",
        "duplicates_modal": {
            "title": "Showing duplicates for ",
            "close": "Close",
            "reason": "Reason",
            "reasons": {
                "same place": "Same place",
                "same address": "Same address",
                "same customer_ref": "Same customer reference",
                "close point": "Close point"
            },
            "open_dvm": "Open Valuation Tool for this request",
            "copy_ref": "Copy request's reference"
        },
        "tabs": {
            "my_requests": "My Requests",
            "last_month": "Last month",
            "all_requests": "All Requests",
            "search_request": "Search Request"
        },
        "need_someone_else": "Need to work on someone else's request?",
        "ing": {
            "customer_ref": "LOP ID"
        },
        "valuers_workload": "Valuers Workload"
    },
    "fr-BE": {
        "customer": "Client",
        "actions": "Actions",
        "address": "Adresse",
        "type": "Type",
        "automatic_valuation": "Valorisation automatique",
        "cancel": "Annuler",
        "customer_ref": "Référence interne",
        "edit": "Modifier",
        "web_report": "Web report",
        "pdf_report": "PDF report",
        "downloading": "Downloading...",
        "features": {
            "f_epc": "PEB",
            "f_touching_sides": "Nombre de façades",
            "f_construction_year": "Année de construction",
            "f_living_area": "Surface habitable",
            "f_x": "Longitude",
            "f_y": "Latitude"
        },
        "info": "Info",
        "info_title": "Plus d'information",
        "last_modified": "Dernière modification",
        "created_at": "Créé",
        "missing_features": "Les données suivantes sont manquantes, ce qui empêche une valorisation automatique:",
        "ok": "OK",
        "per_page": "par page",
        "request_ref": "Référence de demande",
        "search": "Rechercher",
        "status_col": "Statut",
        "status": {
            "awaiting-onsite": "Expertise sur place nécessaire",
            "under-dvm-valuation": "Envoyé",
            "draft": "À compléter",
            "submitted": "Envoyé",
            "exotic": "Expertise sur place nécessaire",
            "needs-review": "Plus d'information nécessaire",
            "cancelled": "Annulé",
            "valued": "Evalué"
        },
        "badges": {
            "resubmitted": "Soumis de nouveau",
            "needs_on_site": "Expertise sur site recommandée"
        },
        "tooltip_text": "Suivez ici l'évolution de vos demandes",
        "review_tooltip": "Cette demande a été analysée par un expert immobilier et certaines réponses doivent être révisées",
        "view_request": "Voir la demande",
        "dvm": "DVM",
        "lang": "Language",
        "valuer": "Expert immobilier",
        "unassigned": "Non assignée",
        "new_request": "Nouvelle demande",
        "create_report": "Générer un rapport",
        "refresh_report": "Générer un rapport de nouveau",
        "download_report": "Télécharger un rapport",
        "report_loading": "Chargement du rapport",
        "owner": "Demandeur",
        "take_ownership": "Prendre en charge",
        "sub_modal": {
            "content": "Confirmez-vous prendre en charge cette demande ? Le demandeur actuel sera informé du changement d'utilisateur.",
            "actions": {
                "cancel": "Annuler",
                "confirm": "Confirmer"
            }
        },
        "valuer_change_modal": {
            "content": "Cette demande est déjà attribuée. Confirmez-vous que vous voulez changer l'expert assigné? Cela empêchera l'expert précédemment assigné de travailler sur la demande.",
            "actions": {
                "cancel": "Annuler",
                "confirm": "Confirmer"
            }
        },
        "valuer_change_modal": {
            "content": "Cette demande est déjà attribuée. Confirmez-vous que vous voulez changer l'expert assigné? Cela empêchera l'expert précédemment assigné de travailler sur la demande.",
            "actions": {
                "cancel": "Annuler",
                "confirm": "Confirmer"
            }
        },
        "duplicates": "Doublons",
        "duplicates_modal": {
            "title": "Affichage des doublons pour ",
            "close": "Fermer",
            "reason": "Raison",
            "reasons": {
                "same place": "Same place",
                "same address": "Même adresse",
                "same customer_ref": "Même référence interne",
                "close point": "Point proche"
            },
            "open_dvm": "Ouvrir la DVM pour cette requête",
            "copy_ref": "Copier la référence"
        },
        "tabs": {
            "my_requests": "Mes demandes",
            "last_month": "Dernier mois",
            "all_requests": "Toutes les demandes",
            "search_request": "Rechercher une demande"
        },
        "need_someone_else": "Besoin de travailler sur une demande d'un collègue?",
        "ing": {
            "customer_ref": "LOP ID"
        },
        "valuers_workload": "Charge de travail des Valuation Specialists"
    },
    "nl-BE": {
        "customer": "Klant",
        "actions": "Acties",
        "address": "Adres",
        "type": "Type",
        "automatic_valuation": "Automatische waardering",
        "cancel": "Annuleren",
        "customer_ref": "Interne referentie",
        "edit": "Bewerken",
        "web_report": "Web report",
        "pdf_report": "PDF report",
        "downloading": "Downloading...",
        "features": {
            "f_epc": "EPC",
            "f_touching_sides": "Aantal gevels",
            "f_construction_year": "Bouwjaar",
            "f_living_area": "Bewoonbare oppervlakte",
            "f_x": "Longitude",
            "f_y": "Latitude"
        },
        "info": "Info",
        "info_title": "Meer informatie",
        "last_modified": "Laatst gewijzigd",
        "created_at": "Gecreëerd",
        "missing_features": "De volgende functies ontbreken, waardoor automatische waardering niet mogelijk is:",
        "my_requests": "Mijn aanvragen",
        "all_requests": "Alle aanvragen",
        "ok": "OK",
        "per_page": "per pagina",
        "request_ref": "Aanvraag referentie",
        "search": "Zoeken",
        "status_col": "Status",
        "status": {
            "awaiting-onsite": "On-site expertise vereist",
            "under-dvm-valuation": "Ingediend",
            "draft": "Ontwerp",
            "submitted": "Ingediend",
            "exotic": "On-site expertise vereist",
            "needs-review": "Meer informatie nodig",
            "cancelled": "Geannuleerd",
            "valued": "Gewaardeerd"
        },
        "badges": {
            "resubmitted": "Opnieuw ingediend",
            "needs_on_site": "Expertise ter plaatse aanbevolen"
        },
        "tooltip_text": "Volg hier de status van uw aanvraagen",
        "review_tooltip": "Deze aanvraag is door een vastgoeddeskundige geanalyseerd en sommige antwoorden moeten worden herzien",
        "view_request": "Aanvraag bekijken",
        "dvm": "DVM",
        "lang": "Taal",
        "valuer": "Vastgoeddeskundige",
        "unassigned": "Niet toegewezen",
        "new_request": "Nieuwe aanvraag",
        "create_report": "Genereer een verslag",
        "refresh_report": "Genereer een nieuw verslag",
        "download_report": "Verslag opslaan",
        "report_loading": "Verslag is aan het laden",
        "owner": "Aanvrager",
        "take_ownership": "Aanvraag overnemen",
        "sub_modal": {
            "content": "Graag nogmaals bevestiging om deze aanvraag over te nemen. De huidige aanvrager zal hiervan een bericht krijgen.",
            "actions": {
                "cancel": "Annuleren",
                "confirm": "Bevestigen"
            }
        },
        "valuer_change_modal": {
            "content": "Dit verzoek is al toegewezen. Bevestigt u dat u de toegewezen expert wilt veranderen? Hierdoor kan de eerder toegewezen expert niet meer aan de aanvraag werken.",
            "actions": {
                "cancel": "Annuleren",
                "confirm": "Bevestigen"
            }
        },
        "duplicates": "Duplicaten",
        "duplicates_modal": {
            "title": "Duplicaten tonen voor ",
            "close": "Sluiten",
            "reason": "Grond",
            "reasons": {
                "same place": "Same place",
                "same address": "Zelfde adres",
                "same customer_ref": "Zelfde interne referentie",
                "close point": "Dichtbij punt"
            },
            "open_dvm": "Open DVM voor deze aanvraag",
            "copy_ref": "Kopieer de aanvraag referentie"
        },
        "tabs": {
            "my_requests": "Mijn aanvragen",
            "last_month": "Laatste maand",
            "all_requests": "Alle aanvragen",
            "search_request": "Aanvraag zoeken"
        },
        "need_someone_else": "Moet werken aan iemand anders zijn aanvraag?",
        "ing": {
            "customer_ref": "LOP ID"
        },
        "valuers_workload": "Werkdruk Valuations Specialists"
    }
}
</i18n>

<template>
    <div class="container section is-fluid overflow-hidden">
        <b-collapse v-if="isValuer" v-model="workloadOpen" class="card" animation="slide">
            <template #trigger="props">
                <div class="card-header" role="button" :aria-expanded="props.open">
                    <p
                        class="is-size-4 has-text-weight-bold has-text-black card-header-title"
                    >
                        {{ $t('valuers_workload') }}
                    </p>
                    <a class="card-header-icon">
                        <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
                    </a>
                </div>
            </template>
            <div class="card-content">
                <valuer-workload :valuers="valuers" :requests="data" />
            </div>
        </b-collapse>
        <title-with-tooltip :label="t('tooltip_text')">
            <template #title>
                <span class="is-capitalized">Dashboard</span>
            </template>
        </title-with-tooltip>
        <new-request-button>
            <b-button
                :label="$t('dashboard.new_request.title')"
                type="is-primary"
                icon-left="plus"
                class="mt-1"
                size="is-medium"
            />
        </new-request-button>
        <a
            v-if="isValuer && reportLink && !reportLoading"
            class="mt-1 ml-1 is-success button is-medium"
            :href="reportLink"
            :download="`${reportFileName}.csv`"
        >
            <i class="mdi mdi-download mr-2"></i>
            {{ t('download_report') }}
        </a>
        <b-button
            v-if="isValuer"
            :class="{
                'mt-1': true,
                'ml-1': true,
                'button-loading': reportLoading,
            }"
            size="is-medium"
            type="is-primary"
            :icon-left="reportLoading || reportLink ? 'autorenew' : 'download'"
            :disabled="reportLoading"
            @click="generateReport"
        >
            {{
                t(
                    reportLoading
                        ? 'report_loading'
                        : reportLink
                        ? 'refresh_report'
                        : 'create_report'
                )
            }}
        </b-button>

        <div class="columns is-multiline mt-4">
            <div class="column is-12">
                <b-tabs
                    v-if="showTabs"
                    v-model="requestsTab"
                    position="is-centered"
                    class="block"
                >
                    <b-tab-item :label="t('tabs.my_requests')" value="1" />
                    <b-tab-item
                        v-if="showAllRequestsTab"
                        :label="t('tabs.all_requests')"
                        value="2"
                    />
                    <b-tab-item
                        v-if="!showAllRequestsTab"
                        :label="t('tabs.search_request')"
                        value="3"
                    />
                </b-tabs>
                <b-tabs
                    v-if="isValuer"
                    v-model="valuerRequestsTab"
                    position="is-centered"
                    class="block"
                >
                    <b-tab-item :label="t('status.submitted')" value="0" />
                    <b-tab-item :label="t('tabs.last_month')" value="1" />
                    <b-tab-item :label="t('tabs.all_requests')" value="2" />
                    <b-tab-item :label="t('dashboard.valuation_type.ers')" value="3" />
                </b-tabs>
            </div>

            <div
                v-if="requestsTab === '3'"
                class="is-flex is-justify-content-center is-align-items-center is-w100 m-2 mb-5"
            >
                <h3>{{ t('need_someone_else') }}</h3>
                <form
                    class="is-flex is-justify-content-center is-align-items-center"
                    @submit="searchByRefSubmit"
                >
                    <b-input
                        v-model="requestRefSearch"
                        class="mr-1 ml-2"
                        :placeholder="t('customer_ref')"
                    />
                    <b-button
                        type="is-primary"
                        :disabled="isSearchRefButtonDisabled"
                        @click="searchByRef"
                    >
                        {{ t('search') }}
                    </b-button>
                </form>
            </div>

            <!-- input section -->
            <div
                class="is-flex is-w100 px-2 is-align-items-center"
                :class="
                    enabledApps.length > 1
                        ? 'is-justify-content-space-between'
                        : 'is-justify-content-flex-end'
                "
            >
                <div
                    v-if="!isValuer && enabledApps.length > 1"
                    class="request_type_filter"
                >
                    <div v-for="app in enabledApps" :key="`appToggle_${app}`">
                        <b-checkbox
                            v-model="shownTypes"
                            :native-value="requestTypeAppMapping[app]"
                        >
                            <svg-icon :icon="requestTypeAppMapping[app]" scale="1" />
                            {{
                                $t(`dashboard.type_toggles.${requestTypeAppMapping[app]}`)
                            }}
                        </b-checkbox>
                    </div>
                </div>
                <!-- per-page select -->
                <b-field position="is-right">
                    <b-select v-model="perPage" size="is-small">
                        <option v-for="item in perPageList" :key="item" :value="item">
                            {{ item }} {{ t('per_page') }}
                        </option>
                    </b-select>
                </b-field>
            </div>
            <!-- input section -->
            <div class="column is-12">
                <b-table
                    :data="tableData"
                    hoverable
                    mobile-cards
                    paginated
                    scrollable
                    pagination-size="is-small"
                    default-sort="modified_at"
                    default-sort-direction="desc"
                    :loading="isLoading"
                    :per-page="perPage"
                    :current-page.sync="currentPage"
                >
                    <template v-for="column in columns">
                        <b-table-column
                            :key="column.valuation_request_ref"
                            v-bind="column"
                        >
                            <template
                                v-if="column.searchable && !column.date"
                                #searchable="props"
                            >
                                <b-input
                                    v-model="props.filters[props.column.field]"
                                    :placeholder="t('search')"
                                    icon="magnify"
                                    size="is-small"
                                />
                            </template>
                            <template
                                v-else-if="column.searchable && column.date"
                                #searchable="props"
                            >
                                <span class="is-flex">
                                    <b-datepicker
                                        v-model="date_filters[props.column.field]"
                                        :placeholder="t('search')"
                                        icon="magnify"
                                        size="is-small"
                                        class="is-flex-grow-1"
                                    />
                                    <button
                                        v-if="date_filters[props.column.field]"
                                        class="clear-button"
                                        @click="clearDate(props.column.field)"
                                    >
                                        <b-icon icon="close-circle" size="is-small" />
                                    </button>
                                </span>
                            </template>
                            <template v-if="column.date" #default="props">
                                <span :class="dateClass(props.row, column)">
                                    {{ date_format(props.row[column.field]) }}
                                </span>
                            </template>
                            <template
                                v-else-if="column.field === 'valuation_request_ref'"
                                #default="props"
                            >
                                <div
                                    class="is-flex-direction-row is-flex-wrap-nowrap is-align-items-center is-justify-content-center"
                                >
                                    <router-link
                                        v-if="
                                            isValuer &&
                                                (props.row.status === 'submitted' ||
                                                    props.row.status === 'needs-review' ||
                                                    props.row.status === 'valued' ||
                                                    props.row.status === 'exotic')
                                        "
                                        :to="{
                                            name: 'valuation.request',
                                            params: {
                                                action: 'extra-info',
                                                lang: $route.params.lang,
                                            },
                                            query: {
                                                valuation_request_ref:
                                                    props.row.valuation_request_ref,
                                            },
                                        }"
                                    >
                                        {{ props.row[column.field] }}
                                    </router-link>
                                    <span v-else>
                                        {{ props.row[column.field] }}
                                    </span>
                                    <b-button
                                        v-if="
                                            isValuer &&
                                                props.row.other_data.similar_requests &&
                                                props.row.other_data.similar_requests
                                                    .length
                                        "
                                        size="is-small"
                                        type="is-warning"
                                        @click="load_duplicates(props.row)"
                                    >
                                        <svg-icon icon="duplicates" />
                                    </b-button>
                                </div>
                            </template>
                            <template
                                v-else-if="column.field === 'display_request_ref'"
                                #default="props"
                            >
                                <a
                                    :href="
                                        `//${
                                            props.row.deployment
                                        }.rock.estate/valuation/${
                                            $route.params.lang
                                        }/request/extra-info?valuation_request_ref=${
                                            props.row[column.field]
                                        }`
                                    "
                                    target="_blank"
                                >
                                    {{ props.row[column.field] }}
                                </a>
                            </template>
                            <template
                                v-else-if="column.field == 'requested_valuation_type'"
                                #default="props"
                            >
                                <div class="val_type_content">
                                    <svg-icon :icon="props.row[column.field]" scale="1" />
                                    <span>
                                        {{
                                            $t(
                                                `dashboard.valuation_type.${
                                                    props.row[column.field]
                                                }`
                                            )
                                        }}
                                    </span>
                                </div>
                            </template>
                            <template
                                v-else-if="
                                    ['last_submission_lang', 'displayOwner'].includes(
                                        column.field
                                    )
                                "
                                #default="props"
                            >
                                <div class="level top">
                                    <span class="level-left">
                                        {{ props.row[column.field] }}
                                    </span>
                                </div>
                            </template>
                            <template
                                v-else-if="column.field === 'displayStatus'"
                                #default="props"
                            >
                                <span
                                    :class="
                                        !!(
                                            props.row.last_dvm_action_at &&
                                            props.row.status === 'draft'
                                        )
                                            ? 'has-text-weight-bold has-text-danger'
                                            : ''
                                    "
                                >
                                    {{ props.row.displayStatus }}
                                </span>
                                <b-tooltip
                                    v-if="
                                        props.row.last_dvm_action_at &&
                                            props.row.status === 'draft'
                                    "
                                    type="is-danger"
                                    :label="$t('review_tooltip')"
                                    multilined
                                    position="is-right"
                                >
                                    <div
                                        class="is-rounded is-tootip-btn has-text-weight-bold has-text-white has-background-danger"
                                    >
                                        !
                                    </div>
                                </b-tooltip>
                                <b-taglist>
                                    <b-tag v-if="isRecent(props.row)" type="is-warning">
                                        <b-icon icon="clock" size="is-small" />
                                    </b-tag>
                                    <b-tag
                                        v-if="isResubmitted(props.row)"
                                        type="is-warning is-light"
                                    >
                                        {{ t('badges.resubmitted') }}
                                    </b-tag>
                                    <b-tag
                                        v-if="hasRenovation(props.row)"
                                        type="is-warning is-light"
                                    >
                                        {{
                                            `reno ${Math.round(
                                                props.row.features.renovation_cost / 1000
                                            )}k€`
                                        }}
                                    </b-tag>
                                    <b-tag
                                        v-for="purpose of Object.keys(
                                            props.row.document_info
                                        )"
                                        :key="purpose"
                                        type="is-primary is-light"
                                    >
                                        {{ props.row.document_info[purpose] }}
                                        {{ $t(`document.${purpose}`) }}
                                    </b-tag>
                                    <b-tag
                                        v-if="needsOnSite(props.row)"
                                        type="is-danger is-light"
                                    >
                                        {{ t('badges.needs_on_site') }}
                                    </b-tag>
                                </b-taglist>
                            </template>
                            <template
                                v-else-if="column.field === 'action'"
                                #default="props"
                            >
                                <div
                                    v-if="props.row.requested_valuation_type !== 'ers'"
                                    class="buttons is-centered"
                                >
                                    <b-button
                                        v-for="(a, i) in filterShow(
                                            valuationActions,
                                            props.row
                                        )"
                                        :key="i"
                                        size="is-small"
                                        :type="a.type"
                                        :icon-left="a.icon"
                                        :target="a.target"
                                        :tag="
                                            a.to ? 'router-link' : a.href ? 'a' : 'button'
                                        "
                                        :disabled="a.disabled && a.disabled(props.row)"
                                        :href="a.href ? a.href(props.row) : undefined"
                                        :to="a.to ? a.to(props.row) : undefined"
                                        @click="a.click ? a.click(props.row) : undefined"
                                    >
                                        {{
                                            t(
                                                callable(a.label)
                                                    ? a.label(props.row)
                                                    : a.label
                                            )
                                        }}
                                    </b-button>
                                </div>
                                <div v-else class="buttons is-centered">
                                    <b-button
                                        v-if="props.row.status === 'submitted'"
                                        type="is-primary"
                                        size="is-small"
                                        tag="router-link"
                                        :to="{
                                            name: 'ers.processing',
                                            params: {
                                                lang: $route.params.lang,
                                            },
                                            query: {
                                                ref: props.row.valuation_request_ref,
                                            },
                                        }"
                                    >
                                        {{
                                            $t('dashboard.reference_listing.view_button')
                                        }}
                                    </b-button>

                                    <b-button
                                        v-if="
                                            props.row.status === 'draft' &&
                                                props.row.owner.is_self
                                        "
                                        type="is-primary"
                                        size="is-small"
                                        @click="
                                            handleEditDraft(
                                                props.row.valuation_request_ref
                                            )
                                        "
                                    >
                                        {{
                                            $t('dashboard.reference_listing.edit_button')
                                        }}
                                    </b-button>

                                    <b-button
                                        v-if="
                                            showOwnershipButton &&
                                                !props.row.owner.is_self
                                        "
                                        type="is-warning"
                                        icon-left="account-convert"
                                        size="is-small"
                                        @click="open_sub_modal(props.row)"
                                    >
                                        {{ t('take_ownership') }}
                                    </b-button>

                                    <b-button
                                        v-if="
                                            props.row.status === 'valued' &&
                                                props.row.owner.is_self
                                        "
                                        type="is-success"
                                        size="is-small"
                                        tag="router-link"
                                        :to="{
                                            name: 'ers.result',
                                            params: {
                                                lang: $route.params.lang,
                                            },
                                            query: {
                                                ref: props.row.valuation_request_ref,
                                            },
                                        }"
                                    >
                                        {{
                                            $t(
                                                'dashboard.reference_listing.report_button'
                                            )
                                        }}
                                    </b-button>
                                </div>
                            </template>
                            <template
                                v-else-if="column.field === 'displayValuer'"
                                #default="props"
                            >
                                <div class="level top">
                                    <b-field
                                        :type="valuerSelectType(props.row) || ''"
                                        class="mb-0"
                                    >
                                        <b-select
                                            v-model="
                                                valuerSelects[
                                                    props.row.valuation_request_ref
                                                ]
                                            "
                                            size="is-small"
                                            @input="onValuerChange($event, props.row)"
                                        >
                                            <option value="N/A">
                                                {{ t('unassigned') }}
                                            </option>
                                            <option
                                                v-for="val in valuers"
                                                :key="val.email"
                                                :value="val.username"
                                            >
                                                {{ val.email }}
                                            </option>
                                        </b-select>
                                    </b-field>
                                    <b-tag
                                        v-if="valuerSelectType(props.row)"
                                        :type="valuerSelectType(props.row)"
                                        rounded
                                        class="ml-1 valuer-select-badge"
                                    />
                                </div>
                            </template>
                            <template v-else #default="props">
                                {{ props.row[column.field] }}
                            </template>
                        </b-table-column>
                    </template>
                </b-table>
                <!-- table section -->
            </div>
        </div>
        <b-modal
            v-model="valuer_change_modal_open"
            has-modal-card
            trap-focus
            :can-cancel="['escape']"
            :destroy-on-hide="true"
            aria-role="dialog"
            aria-modal
            @close="close_valuer_change_modal"
        >
            <div v-if="valuer_change_modal_open" class="card confirm">
                <div class="modal-card valuer_change_modal p-5">
                    <p class="mb-5 has-text-centered">
                        {{ t('valuer_change_modal.content') }}
                    </p>
                    <div class="buttons is-centered">
                        <b-button
                            type="is-light"
                            size="is-small"
                            @click="close_valuer_change_modal"
                        >
                            {{ t('valuer_change_modal.actions.cancel') }}
                        </b-button>
                        <b-button
                            type="is-primary"
                            size="is-small"
                            @click="
                                updateValuer(modal_selected_valuer, modal_selected_row)
                            "
                        >
                            {{ t('valuer_change_modal.actions.confirm') }}
                        </b-button>
                    </div>
                </div>
            </div>
        </b-modal>
        <b-modal
            v-model="substitution_modal_open"
            has-modal-card
            trap-focus
            :can-cancel="['escape']"
            :destroy-on-hide="true"
            aria-role="dialog"
            aria-modal
        >
            <div v-if="substitution_modal_open" class="card confirm">
                <div class="modal-card sub_modal p-5">
                    <p class="mb-5 has-text-centered">{{ t('sub_modal.content') }}</p>
                    <div class="buttons is-centered">
                        <b-button
                            type="is-light"
                            size="is-small"
                            @click="close_sub_modal"
                        >
                            {{ t('sub_modal.actions.cancel') }}
                        </b-button>
                        <b-button
                            type="is-primary"
                            size="is-small"
                            @click="take_ownership"
                        >
                            {{ t('sub_modal.actions.confirm') }}
                        </b-button>
                    </div>
                </div>
            </div>
        </b-modal>
        <b-modal
            v-model="shownDuplicate"
            trap-focus
            :can-cancel="['escape']"
            width="fit-content"
        >
            <div v-if="shownDuplicate" class="card p-4">
                <p class="mb-5 has-text-centered is-size-5 has-text-black">
                    <b>
                        {{ $t('duplicates_modal.title') }}
                        {{ shownDuplicate.valuation_request_ref }}
                    </b>
                </p>
                <b-table
                    :data="duplicatesData"
                    hoverable
                    mobile-cards
                    paginated
                    scrollable
                    pagination-size="is-small"
                    default-sort="modified_at"
                    default-sort-direction="desc"
                    :loading="isDuplicatesLoading"
                    :per-page="10"
                    :current-page.sync="currentDuplicatesPage"
                    :searchable="false"
                    class="duplicates-table"
                >
                    <template v-for="column in duplicates_columns">
                        <b-table-column
                            :key="column.valuation_request_ref"
                            v-bind="column"
                        >
                            <template #header>
                                <div
                                    class="is-flex-direction-column is-align-items-center is-justify-content-flex-end"
                                >
                                    <div class="nowrap">{{ column.label }}</div>
                                    <div class="has-text-primary mt-3">
                                        {{
                                            column.date
                                                ? date_format(
                                                      shownDuplicate[column.field]
                                                  )
                                                : shownDuplicate[column.field]
                                        }}
                                    </div>
                                </div>
                            </template>
                            <template v-if="column.date" #default="props">
                                <span :class="dateClass(props.row, column)">
                                    {{ date_format(props.row[column.field]) }}
                                </span>
                            </template>
                            <template
                                v-else-if="column.field === 'reasons'"
                                #default="props"
                            >
                                {{ generateReasons(props.row[column.field]) }}
                            </template>
                            <template
                                v-else-if="column.field === 'valuation_request_ref'"
                                #default="props"
                            >
                                <router-link
                                    v-if="
                                        isValuer &&
                                            (props.row.status === 'submitted' ||
                                                props.row.status === 'needs-review' ||
                                                props.row.status === 'valued' ||
                                                props.row.status === 'exotic')
                                    "
                                    :to="{
                                        name: 'valuation.request',
                                        params: {
                                            action: 'extra-info',
                                            lang: $route.params.lang,
                                        },
                                        query: {
                                            valuation_request_ref:
                                                props.row.valuation_request_ref,
                                        },
                                    }"
                                    target="_blank"
                                >
                                    {{ props.row[column.field] }}
                                </router-link>
                                <span v-else>
                                    {{ props.row[column.field] }}
                                </span>
                            </template>
                            <template
                                v-else-if="column.field === 'display_request_ref'"
                                #default="props"
                            >
                                <a
                                    :href="
                                        `//${
                                            props.row.deployment
                                        }.rock.estate/valuation/${
                                            $route.params.lang
                                        }/request/extra-info?valuation_request_ref=${
                                            props.row[column.field]
                                        }`
                                    "
                                    target="_blank"
                                >
                                    {{ props.row[column.field] }}
                                </a>
                            </template>
                            <template
                                v-else-if="
                                    ['last_submission_lang', 'displayOwner'].includes(
                                        column.field
                                    )
                                "
                                #default="props"
                            >
                                <div class="level top">
                                    <span class="level-left">
                                        {{ props.row[column.field] }}
                                    </span>
                                </div>
                            </template>
                            <template v-else #default="props">
                                {{ props.row[column.field] }}
                            </template>
                        </b-table-column>
                    </template>
                </b-table>
                <div
                    class="buttons is-flex-direction-row is-justify-content-end is-align-items-center mt-5"
                >
                    <b-button
                        v-if="
                            (shownDuplicate.status === 'submitted' ||
                                shownDuplicate.status === 'needs-review' ||
                                shownDuplicate.status === 'valued' ||
                                shownDuplicate.status === 'exotic') &&
                                DVMEnabled
                        "
                        type="is-primary"
                        :tag="isDispatchDashboard ? 'a' : 'router-link'"
                        :target="isDispatchDashboard ? '_blank' : null"
                        :href="
                            isDispatchDashboard
                                ? `https://${shownDuplicate.deployment}.rock.estate/valuation/${$route.params.lang}/dvm?valuation_request_ref=${shownDuplicate.display_request_ref}`
                                : null
                        "
                        :to="{
                            name: 'valuation.dvm',
                            query: {
                                valuation_request_ref:
                                    shownDuplicate.valuation_request_ref,
                            },
                        }"
                    >
                        {{ $t('duplicates_modal.open_dvm') }}
                    </b-button>
                    <b-button @click="close_duplicates">
                        {{ $t('duplicates_modal.close') }}
                    </b-button>
                </div>
            </div>
        </b-modal>
        <!--<b-modal
            v-model="modal_open"
            has-modal-card
            trap-focus
            :can-cancel="['escape']"
            :destroy-on-hide="false"
            aria-role="dialog"
            aria-modal
        >
            <div v-if="modal_open" class="card confirm">
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">{{ t('info_title') }}</p>
                    </header>
                    <b-message type="is-primary" has-icon class="mb-0" size="is-medium">
                        <span class="is-modal-message">
                            {{ t('missing_features') }}

                            <ul>
                                <li
                                    v-for="(error,
                                    index) in row_data.avm_transaction.body.errors.filter(
                                        (error) => error.message === 'missing_parameter'
                                    )"
                                    :key="index"
                                >
                                    {{ t(`features.${error.parameter}`) }}
                                </li>
                            </ul>
                        </span>
                    </b-message>
                    <footer class="modal-card-foot">
                        <b-button
                            :label="t('ok')"
                            type="is-primary"
                            icon-left="close"
                            expanded
                            @click="modal_open = false"
                        />
                    </footer>
                </div>
            </div>
        </b-modal>-->
    </div>
</template>

<script>
import axios from '@/shared/plugins/axios.js'
import utils from '@/shared/plugins/utils'
import { mapGetters } from 'vuex'
import { localeMixin } from '@/components/mixins/locale.js'
import ValuerWorkload from './ValuerWorkload.vue'
import NewRequestButton from '@/components/common/NewRequestButton.vue'
import store from '@/store/index.js'

const date_formatter = new Intl.DateTimeFormat('fr-BE', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    /*second: 'numeric',*/
    timeZone: 'Europe/Brussels',
})

export default {
    name: 'Overview',
    components: { ValuerWorkload, NewRequestButton },
    mixins: [localeMixin],
    data() {
        return {
            shownTypes: [],
            workloadOpen: false,
            data: [],
            valuers: [],
            searchQuery: '',
            currentPage: 1,
            perPage: 10,
            perPageList: [5, 10, 25, 50],
            isLoading: true,
            shownDuplicate: null,
            isDuplicatesLoading: false,
            duplicatesData: [],
            currentDuplicatesPage: 1,
            loading_error: false,
            result: [],
            //modal_open: false,
            row_data: {},
            disabled_downloads: [],
            now: Date.now(),
            reportLoading: false,
            reportLink: null,
            reportFileName: 'billing-report',
            modal_selected_row: null,
            modal_selected_valuer: null,
            substitution_modal_open: false,
            valuer_change_modal_open: false,
            owner_search_query: null,
            owner_emails: null,
            valuerSelects: {},
            requestsTab: '1',
            valuerRequestsTab: '1',
            requestRefSearch: '',
            date_filters: {
                created_at: null,
                modified_at: null,
            },
        }
    },
    computed: {
        ...mapGetters(['flow_type']),
        ...mapGetters('auth', ['hasRole', 'submissionsAvailable']),
        // return filtered text
        DVMEnabled() {
            return this.$config.ENABLE_DVM
        },
        newDVMEnabled() {
            return this.$config.ENABLE_NEW_DVM
        },
        OVMEnabled() {
            return this.$config.ENABLE_OVM
        },
        enabledApps() {
            return this.$config.ENABLED_APPS
        },
        columns() {
            return [
                {
                    field: 'deployment',
                    label: this.$t('customer'),
                    width: '30',
                    visible: this.isDispatchDashboard,
                    searchable: true,
                },
                {
                    field: 'customer_ref',
                    label: this.$t('customer_ref'),
                    width: '40',
                    sortable: true,
                    centered: true,
                    searchable: this.requestsTab < 3,
                },
                {
                    field: 'valuation_request_ref',
                    visible: !this.isDispatchDashboard,
                    label: this.$t('request_ref'),
                    centered: true,
                    searchable: this.requestsTab < 3,
                },
                {
                    field: 'requested_valuation_type',
                    visible: !this.isValuer && this.enabledApps.length > 1,
                    label: this.$t('type'),
                    centered: true,
                },
                {
                    field: 'display_request_ref',
                    label: this.$t('request_ref'),
                    visible: this.isDispatchDashboard,
                    centered: true,
                    searchable: this.requestsTab < 3,
                },
                {
                    field: 'display_building_type',
                    label: this.$t('features.f_building_type'),
                    centered: true,
                    searchable: this.requestsTab < 3,
                },
                {
                    field: 'address',
                    label: this.$t('address'),
                    centered: true,
                    searchable: this.requestsTab < 3,
                },
                {
                    field: 'displayStatus',
                    label: this.$t('status_col'),
                    centered: true,
                    searchable: this.requestsTab < 3,
                    sortable: true,
                },
                {
                    field: 'created_at',
                    label: this.$t('created_at'),
                    centered: true,
                    sortable: true,
                    date: true,
                    searchable: this.enableDateSearch,
                },
                {
                    field: 'modified_at',
                    label: this.$t('last_modified'),
                    centered: true,
                    sortable: true,
                    date: true,
                    searchable: this.enableDateSearch,
                },
                {
                    field: 'action',
                    label: this.$t('actions'),
                    centered: true,
                },
                {
                    field: 'displayOwner',
                    label: this.$t('owner'),
                    visible: this.showOwner,
                    centered: true,
                    sortable: true,
                    searchable: this.requestsTab < 3,
                },
                {
                    field: 'last_submission_lang',
                    label: this.$t('lang'),
                    visible: this.isValuer,
                    centered: true,
                    sortable: true,
                    searchable: this.requestsTab < 3,
                },
                {
                    field: 'displayValuer',
                    label: this.$t('valuer'),
                    visible: this.isValuer && this.valuerRequestsTab < 3,
                    centered: true,
                    sortable: true,
                    searchable: this.requestsTab < 3,
                },
            ]
        },
        duplicates_columns() {
            return [
                {
                    field: 'deployment',
                    label: this.$t('customer'),
                    width: '30',
                    visible: this.isDispatchDashboard,
                },
                {
                    field: 'customer_ref',
                    label: this.$t('customer_ref'),
                    width: '40',
                    sortable: true,
                    centered: true,
                },
                {
                    field: 'valuation_request_ref',
                    label: this.$t('request_ref'),
                    visible: !this.isDispatchDashboard,
                    centered: true,
                },
                {
                    field: 'display_request_ref',
                    label: this.$t('request_ref'),
                    visible: this.isDispatchDashboard,
                    centered: true,
                },
                {
                    field: 'address',
                    label: this.$t('address'),
                    centered: true,
                },
                {
                    field: 'reasons',
                    label: this.$t('duplicates_modal.reason'),
                    sortable: true,
                },
                {
                    field: 'displayStatus',
                    label: this.$t('status_col'),
                    centered: true,
                    sortable: true,
                },
                {
                    field: 'created_at',
                    label: this.$t('created_at'),
                    centered: true,
                    sortable: true,
                    date: true,
                },
                {
                    field: 'displayOwner',
                    label: this.$t('owner'),
                    visible: this.showOwner,
                    centered: true,
                    sortable: true,
                },
                {
                    field: 'displayValuer',
                    label: this.$t('valuer'),
                    visible: this.isValuer,
                    centered: true,
                    sortable: true,
                },
            ]
        },
        enableDateSearch() {
            return this.$config.SHOW_DATE_FILTERS
        },
        tableData() {
            let date_threshold = new Date(2021, 4, 26, 23, 0, 0, 0)
            return this.data
                .map((row, index) => {
                    this.valuerSelects[row.valuation_request_ref] = !this.isValuer
                        ? 'N/A'
                        : row.valuer.assigned
                        ? row.valuer.username
                        : 'N/A'
                    return {
                        ...row,
                        index: index,
                        deployment: this.isDispatchDashboard
                            ? row.valuation_request_ref.split('_')[0]
                            : null,
                        display_request_ref: this.isDispatchDashboard
                            ? row.valuation_request_ref.split('_')[1]
                            : null,
                        display_building_type: row.building_type
                            ? this.$t(`value.${row.building_type}`)
                            : '',
                        displayStatus:
                            row.requested_valuation_type === 'ers' &&
                            row.status === 'valued'
                                ? this.$t('status.completed')
                                : this.$t(`status.${row.status}`),
                        displayOwner: row.owner ? row.owner.email : '',
                        displayValuer: !this.isValuer
                            ? 'N/A'
                            : row.valuer.assigned
                            ? row.valuer.username
                            : 'N/A',
                        disabled: this.disabled_downloads.includes(
                            row.valuation_request_ref
                        ),
                    }
                })
                .filter((item) => {
                    return (
                        !['cancelled', 'predraft'].includes(item.status) &&
                        this.shownTypes.includes(item.requested_valuation_type) &&
                        item.modified_at >= date_threshold &&
                        (this.isValuer && this.valuerRequestsTab === '0'
                            ? item.status === 'submitted'
                            : true) &&
                        (this.date_filters.created_at
                            ? utils.dateDiffInDays(
                                  this.date_filters.created_at,
                                  new Date(item.created_at)
                              ) === 0
                            : true) &&
                        (this.date_filters.modified_at
                            ? utils.dateDiffInDays(
                                  this.date_filters.modified_at,
                                  new Date(item.modified_at)
                              ) === 0
                            : true)
                    )
                })
        },
        visible_reports() {
            if (
                ['rock.estate', 'rockestate.be'].includes(
                    this.$store.state.auth.organization
                )
            ) {
                return ['avm_pdf', 'dvm_pdf', 'avm_web', 'dvm_web']
            } else {
                return this.$config.REPORT_VISIBLE
            }
        },
        isReporter() {
            return this.hasRole('reporter')
        },
        isValuer() {
            return this.hasRole('valuer')
        },
        isSubstitute() {
            return this.hasRole('substitute')
        },
        showOwner() {
            return (
                this.isValuer ||
                (this.isSubstitute &&
                    this.showTabs &&
                    ['2', '3'].includes(this.requestsTab))
            )
        },
        showTabs() {
            return (
                !this.isValuer &&
                (this.isReporter ||
                    (this.isSubstitute && this.$config.SUBSTITUTE_FEATURE_ENABLED))
            )
        },
        showOwnershipButton() {
            return (
                this.isSubstitute &&
                !this.isValuer &&
                this.$config.SUBSTITUTE_FEATURE_ENABLED
            )
        },
        showAllRequestsTab() {
            return this.isReporter || (this.showTabs && this.$config.SHOW_ALL_REQUESTS)
        },
        isSearchRefButtonDisabled() {
            return utils.isEmptyStr(this.requestRefSearch)
        },
        isDispatchDashboard() {
            return !!this.$config.DISPATCHED_DEPLOYMENTS?.length
        },
        requestTypeAppMapping() {
            return {
                ers: 'ers',
                valuation: 'dvm',
            }
        },
        valuationActions() {
            return [
                // {
                //     label: 'info',
                //     icon: 'help-circle',
                //     show: (r) => r.status === 'needs-review',
                //     click: (r) => this.view_details(r),
                // },
                {
                    label: 'edit',
                    type: 'is-warning',
                    icon: 'pencil',
                    target: this.isDispatchDashboard ? '_blank' : null,
                    show: (r) => r.owner.is_self && r.status === 'draft',
                    href: (r) =>
                        this.isDispatchDashboard
                            ? `https://${r.deployment}.rock.estate/valuation/${$route.params.lang}/request/extra-info?valuation_request_ref=${r.display_request_ref}`
                            : null,
                    to: (r) =>
                        !this.isDispatchDashboard
                            ? {
                                  name: 'valuation.edit',
                                  query: {
                                      valuation_request_ref: r.valuation_request_ref,
                                  },
                              }
                            : null,
                },
                {
                    label: 'edit',
                    type: 'is-warning',
                    icon: 'pencil',
                    show: (r) =>
                        r.owner.is_self &&
                        r.valuation_type !== 'ovm' &&
                        r.status === 'submitted' &&
                        this.now - r.modified_at < 1000 * 60 * 60,
                    click: (r) => this.unsubmit(r),
                },
                {
                    label: 'cancel',
                    type: 'is-danger',
                    icon: 'cancel',
                    show: (r) =>
                        (!this.isDispatchDashboard && this.isValuer) ||
                        (r.owner.is_self &&
                            (r.status === 'draft' ||
                                (r.status === 'submitted' &&
                                    this.now - r.modified_at < 1000 * 60 * 60))),
                    click: (r) => this.cancel(r),
                },
                {
                    label: 'borrower_view',
                    type: 'is-primary',
                    icon: 'eye',
                    show: (r) =>
                        r.borrower && r.borrower.is_self && r.status === 'submitted',
                    to: (r) => ({
                        name: 'valuation.ovm.borrower',
                        query: {
                            step: r.ovm_status,
                            valuation_request_ref: r.valuation_request_ref,
                        },
                    }),
                },
                {
                    label: 'appointment_summary',
                    type: 'is-primary',
                    icon: 'eye',
                    show: (r) => r.valuation_type === 'ovm', // TODO: refine who can see this
                    to: (r) => ({
                        name: 'valuation.ovm.summary',
                        query: {
                            valuation_request_ref: r.valuation_request_ref,
                        },
                    }),
                },
                {
                    label: 'view_request',
                    icon: 'eye',
                    show: (r) =>
                        ((r.owner.is_self || this.isReporter) &&
                            !this.isValuer &&
                            ['submitted', 'needs-review', 'valued', 'exotic'].includes(
                                r.status
                            )) ||
                        (r.status === 'awaiting-onsite' && this.OVMEnabled),
                    to: (r) => ({
                        name: 'valuation.request',
                        params: {
                            action: r.valuation_type === 'ovm' ? 'ovm' : 'extra-info',
                        },
                        query: {
                            valuation_request_ref: r.valuation_request_ref,
                        },
                    }),
                },
                {
                    label: 'dvm',
                    type: 'is-primary',
                    icon: 'eye',
                    target: this.isDispatchDashboard ? '_blank' : null,
                    show: (r) =>
                        this.hasRole('valuer', 'dvm') &&
                        [
                            'submitted',
                            'needs-review',
                            'valued',
                            'exotic',
                            'awaiting-onsite',
                        ].includes(r.status) &&
                        this.DVMEnabled,
                    href: (r) =>
                        this.isDispatchDashboard
                            ? `https://${r.deployment}.rock.estate/valuation/${$route.params.lang}/dvm?valuation_request_ref=${r.display_request_ref}`
                            : null,
                    to: (r) =>
                        !this.isDispatchDashboard
                            ? {
                                  name: this.newDVMEnabled
                                      ? 'valuation.valuer-app'
                                      : 'valuation.dvm',
                                  query: {
                                      valuation_request_ref: r.valuation_request_ref,
                                  },
                              }
                            : null,
                },
                // {
                //     label: 'automatic_valuation',
                //     type: 'is-success',
                //     icon: 'eye',
                //     show: (r) => r.status === 'submitted' || r.status === 'valued',
                //     to: (r) => ({
                //         name: 'valuation.avm',
                //         params: { step: 1 },
                //         query: {
                //             valuation_request_ref: r.valuation_request_ref,
                //         },
                //     }),
                // },
                {
                    label: 'web_report',
                    icon: 'file-document-outline',
                    type: 'is-success',
                    show: (r) =>
                        (r.owner.is_self || this.isReporter || this.isValuer) &&
                        this.showReport(r.status, 'web'),
                    to: (r) => ({
                        name: 'valuation.report',
                        query: {
                            valuation_request_ref: r.valuation_request_ref,
                            static: 'true',
                        },
                    }),
                },
                {
                    label: (r) => (r.disabled ? 'downloading' : 'pdf_report'),
                    type: 'is-success',
                    icon: 'download',
                    show: (r) =>
                        (r.owner.is_self || this.isReporter || this.isValuer) &&
                        this.showReport(r.status, 'pdf'),
                    href: (r) => this.report_url(r),
                    click: (r) => this.disable_button(r.valuation_request_ref),
                    disabled: (r) => r.disabled,
                },
                {
                    label: 'take_ownership',
                    type: 'is-success',
                    show: (r) => this.showOwnershipButton && !r.owner.is_self,
                    click: (r) => this.open_sub_modal(r),
                },
            ]
        },
    },
    watch: {
        // fire search function on input
        // we can add debounce or length condition until search fires
        searchQuery(val) {
            this.search(val)
        },
        requestsTab(val) {
            this.currentPage = 1
            this.data = []
            if (this.requestsTab < 3) {
                this.load_data()
            }
        },
        valuerRequestsTab(val) {
            this.currentPage = 1
            this.data = []
            if (val === '3') {
                this.shownTypes = ['ers']
            } else {
                this.shownTypes = ['dvm']
            }
            if (this.requestsTab < 4) {
                this.load_data()
            }
        },
        isValuer(val) {
            const shownTypes = []

            if (val) {
                this.shownTypes = ['dvm']
            } else {
                this.enabledApps.map((app) => {
                    shownTypes.push(this.requestTypeAppMapping[app])
                })
                this.shownTypes = shownTypes
            }
            this.requestsTab = '1'
            this.valuerRequestsTab = '1'
            if (process.env.NODE_ENV.toLowerCase() === 'development') {
                this.data = []
                this.load_valuers()
                this.load_data()
            }
        },
    },
    // load data
    mounted() {
        const shownTypes = []

        if (this.isValuer) {
            this.shownTypes = ['dvm']
        } else {
            this.enabledApps.map((app) => {
                shownTypes.push(this.requestTypeAppMapping[app])
            })
            this.shownTypes = shownTypes
        }
        if (this.$route.query.hasOwnProperty('search')) {
            this.searchQuery = this.$route.query.search
        } else if (localStorage.getItem('dashboard_search')) {
            this.searchQuery = localStorage.getItem('dashboard_search')
        }
        this.load_data()
        if (this.isValuer) {
            this.load_valuers()
        }

        var self = this
        setInterval(function() {
            self.now = Date.now()
        }, 1000)
    },
    metaInfo() {
        return {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: 'Dashboard',
            // all titles will be injected into this template
            titleTemplate: '%s | Rock.estate Valuation App',
        }
    },
    methods: {
        showReport(status, variant) {
            return (
                (status === 'valued' &&
                    this.visible_reports.includes('dvm_' + variant)) ||
                (status === 'submitted' &&
                    this.visible_reports.includes('avm_' + variant))
            )
        },
        callable(func) {
            return func instanceof Function
        },
        filterShow(actions, row) {
            return actions.filter((action) => {
                if (action.show instanceof Function) {
                    return action.show(row)
                } else {
                    return action.show
                }
            })
        },
        requestTypeIcon(requestType) {
            return utils.requestTypeIcon(requestType)
        },
        // search with multiple query
        search(search) {
            localStorage.setItem('dashboard_search', search)
        },
        generateReasons(list) {
            return list
                .map((reason) => this.$t(`duplicates_modal.reasons.${reason}`))
                .join(', ')
        },
        date_format: date_formatter.format,
        report_url(row) {
            const lang = row.last_submission_lang || this.$route.params.lang
            const baseString = this.isDispatchDashboard
                ? `//${row.deployment}.rock.estate`
                : ''
            let valuation_request_ref = this.isDispatchDashboard
                ? row.display_request_ref
                : row.valuation_request_ref
            return (
                baseString +
                `${utils.urlJoin(this.$config.VALUATION_API_URL, '/report')}?` +
                `valuation_request_ref=${valuation_request_ref}` +
                `&lang=${lang}`
            )
        },
        button_enabled(valuation_request_ref) {
            return this.disabled_downloads.contains(valuation_request_ref)
        },
        disable_button(valuation_request_ref) {
            this.disabled_downloads.push(valuation_request_ref)
        },
        cancel(row) {
            axios
                .put(
                    utils
                        .val_urls(this.$config)
                        .request_ref_status(row.valuation_request_ref),
                    null,
                    {
                        params: { action: 'cancel' },
                    }
                )
                .then((response) =>
                    this.$set(this.data[row.index], 'status', 'cancelled')
                )
        },
        unsubmit(row) {
            axios
                .put(
                    utils
                        .val_urls(this.$config)
                        .request_ref_status(row.valuation_request_ref),
                    null,
                    {
                        params: { action: 'unsubmit' },
                    }
                )
                .then((response) => this.edit_draft(row))
        },
        display_address(el) {
            let a = utils.full_address(el.address, el.features)
            if (utils.via_address(el.address, el.features) !== '') {
                a = `${a} (via ${utils.via_address(el.address, el.features)})`
            }
            return a
        },
        load_data() {
            let url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                !this.isValuer && (!this.showTabs || this.requestsTab === '1')
                    ? 'user'
                    : null,
                'requests',
            ])
            const params = {}
            if (typeof this.$route.query.valuation_request_refs !== 'undefined') {
                params.valuation_request_refs = this.$route.query.valuation_request_refs
            }
            if (this.isValuer && this.valuerRequestsTab > 1) {
                params.load_all = 'true'
            }
            this.isLoading = true
            this.loading_error = false
            this.data = []
            axios
                .get(url, { params })
                .then((result) => {
                    const data = result.data
                    let index = 0
                    data.forEach((el, __) => {
                        try {
                            this.$set(this.data, index, {
                                customer_ref: el.customer_ref,
                                valuation_request_ref: el.valuation_request_ref,
                                address: this.display_address(el),
                                document_info: el.document_info ? el.document_info : {},
                                building_type: el.features?.f_building_type
                                    ? el.features?.f_building_type
                                    : null,
                                features: el.features,
                                status: el.status,
                                modified_at: new Date(el.modified_at),
                                created_at: new Date(el.created_at),
                                owner: el.owner,
                                borrower: el.borrower,
                                last_submission_lang: el.last_submission_lang,
                                last_submission_at: el.last_submission_at,
                                last_dvm_action_at: el.last_dvm_action_at
                                    ? new Date(el.last_dvm_action_at)
                                    : null,
                                valuer: el.valuer,
                                dvm_admissible: el.dvm_admissible,
                                other_data: el.other_data,
                                requested_valuation_type: el.requested_valuation_type,
                                valuation_type: el.valuation_type,
                            })
                        } catch (error) {
                            console.error({ error, el })
                        }
                        index += 1
                    })
                    this.isLoading = false
                })
                .catch((error) => {
                    this.isLoading = false
                    this.loading_error = true
                })
        },
        handleEditDraft(ref) {
            this.isLoading = true
            store.dispatch('ersStore/fetch_features', ref).then((res) => {
                axios
                    .get(utils.urlJoin(this.$config.VALUATION_API_URL, `request/${ref}`))
                    .then((res) => {
                        const lastVisitedPage = res.data.other_data.last_page_info.name
                        if (lastVisitedPage) {
                            this.$router.push({
                                name: lastVisitedPage,
                                query: {
                                    ref,
                                },
                            })
                        }
                        this.isLoading = false
                    })
            })
        },
        searchByRef() {
            let url = utils.urlJoin(this.$config.VALUATION_API_URL, 'requests')
            let params = { customer_ref: this.requestRefSearch }

            this.isLoading = true
            this.loading_error = false
            this.data = []
            axios
                .get(url, { params })
                .then((result) => {
                    const data = result.data
                    let index = 0
                    data.forEach((el, __) => {
                        try {
                            this.$set(this.data, index, {
                                customer_ref: el.customer_ref,
                                valuation_request_ref: el.valuation_request_ref,
                                address: utils.full_address(el.address, el.features),
                                status: el.status,
                                modified_at: new Date(el.modified_at),
                                created_at: new Date(el.created_at),
                                owner: el.owner,
                                last_submission_lang: el.last_submission_lang,
                                last_submission_at: el.last_submission_at,
                                last_dvm_action_at: el.last_dvm_action_at
                                    ? new Date(el.last_dvm_action_at)
                                    : null,
                                valuer: el.valuer,
                                dvm_admissible: el.dvm_admissible,
                            })
                        } catch (error) {
                            console.error({ error, el })
                        }
                        index += 1
                    })
                    this.isLoading = false
                })
                .catch((error) => {
                    this.isLoading = false
                    this.loading_error = true
                })
        },
        searchByRefSubmit(event) {
            event.preventDefault()
            if (!this.isSearchRefButtonDisabled) {
                this.searchByRef()
            }
        },
        load_valuers() {
            let url = utils.urlJoin(this.$config.VALUATION_API_URL, 'users')
            let params = { roles: 'valuer' }
            axios.get(url, { params }).then((result) => {
                this.valuers = result.data
                this.valuers.sort((a, b) => {
                    if (a.email < b.email) return -1
                    if (a.email > b.email) return 1
                    return 0
                })
            })
        },
        edit_draft(row) {
            this.$router.push({
                name: 'valuation.request',
                params: {
                    action: 'extra-info',
                    lang: this.$route.params.lang,
                },
                query: {
                    valuation_request_ref: row.valuation_request_ref,
                },
            })
        },
        open_sub_modal(row) {
            this.modal_selected_row = {
                ...this.data[row.index],
                index: row.index,
            }
            this.substitution_modal_open = true
        },
        close_sub_modal() {
            this.substitution_modal_open = false
            this.modal_selected_row = null
        },
        close_valuer_change_modal() {
            if (this.valuer_change_modal_open) {
                const prevValuer = !this.isValuer
                    ? 'N/A'
                    : this.modal_selected_row.valuer.assigned
                    ? this.modal_selected_row.valuer.email
                    : 'N/A'
                this.valuerSelects[
                    this.modal_selected_row.valuation_request_ref
                ] = prevValuer
                this.valuer_change_modal_open = false
                this.modal_selected_row = null
                this.modal_selected_valuer = null
            }
        },
        take_ownership() {
            axios
                .put(
                    utils
                        .val_urls(this.$config)
                        .request_ref_owner(this.modal_selected_row.valuation_request_ref)
                )
                .then((response) => {
                    this.$set(
                        this.data[this.modal_selected_row.index],
                        'owner',
                        response.data
                    )

                    this.close_sub_modal()
                })
                .catch((error) => {
                    console.error(error)
                })
        },
        onValuerChange(valuer, row) {
            if (valuer === 'N/A') {
                this.release_request(row)
            } else if (!row.valuer.assigned) {
                this.updateValuer(valuer, row)
            } else {
                this.modal_selected_row = row
                this.modal_selected_valuer = valuer
                this.valuer_change_modal_open = true
            }
        },
        release_request(row) {
            return axios
                .put(
                    utils
                        .val_urls(this.$config)
                        .request_ref_valuer(row.valuation_request_ref),
                    null,
                    {
                        params: { release: 'true' },
                    }
                )
                .then((response) => {
                    this.$set(this.data[row.index], 'valuer', response.data)
                })
                .catch((error) => {
                    console.error(error)
                })
        },
        updateValuer(username, row) {
            return axios
                .put(
                    utils
                        .val_urls(this.$config)
                        .request_ref_valuer(row.valuation_request_ref),
                    null,
                    {
                        params: { username, steal: true },
                    }
                )
                .then((response) => {
                    this.$set(this.data[row.index], 'valuer', response.data)
                    this.close_valuer_change_modal()
                })
                .catch((error) => {
                    console.error(error)
                })
        },
        isResubmitted(row) {
            if (!this.isValuer || !row.last_dvm_action_at) return false

            const lastSubmit = new Date(row.last_submission_at).getTime()

            if (row.status === 'submitted' && lastSubmit > row.last_dvm_action_at) {
                return true
            } else {
                return false
            }
        },
        hasRenovation(row) {
            return this.isValuer && row.features.renovation_cost
        },
        documentInfo(row) {
            if (!row.document_info) {
                return {}
            } else {
                return row.document_info
            }
        },
        needsOnSite(row) {
            if (
                this.isValuer &&
                row.status === 'submitted' &&
                row.dvm_admissible === false
            ) {
                return true
            } else {
                return false
            }
        },
        isRecent(row) {
            if (
                this.isValuer &&
                row.status === 'submitted' &&
                this.now - row.modified_at < 1000 * 60 * 60
            ) {
                return true
            }
            return false
        },
        generateReport() {
            this.reportLoading = true
            return axios
                .get(`${utils.urlJoin(this.$config.VALUATION_API_URL, 'billing_report')}`)
                .then((response) => {
                    var now = new Date()
                    var blob = new Blob(['\ufeff', response.data])
                    this.reportLink = URL.createObjectURL(blob)
                    this.reportFileName = `billing-report-${utils.formatDate(now)}`

                    this.reportLoading = false
                })
                .catch((error) => {
                    console.error(error)
                    this.reportLoading = false
                })
        },
        valuerSelectType(row) {
            if (!this.isValuer || row.status !== 'submitted') return false
            if (!row.valuer.assigned) return 'is-success'
            if (row.valuer.is_self) return 'is-primary'
            return 'is-danger'
        },
        dateClass(row, column) {
            if (!this.isValuer) return false
            if (column.field !== 'modified_at') return false
            if (row.status !== 'submitted') return false
            const submission = new Date(row.last_submission_at)
            const now = new Date()
            const difference = utils.dateDiffInBusinessDays(submission, now)
            if (difference === 1) return 'has-text-primary'
            if (difference >= 2) return 'has-text-danger'
            return false
        },
        clearDate(field) {
            this.date_filters[field] = null
        },
        load_duplicates(selected_row) {
            this.shownDuplicate = selected_row
            this.shownDuplicate.created_at = new Date(selected_row.created_at)
            this.isDuplicatesLoading = true
            this.currentDuplicatesPage = 1
            let url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                !this.isValuer && (!this.showTabs || this.requestsTab === '1')
                    ? 'user'
                    : null,
                'requests',
            ])
            let dupl = {}
            const params = {}
            params.valuation_request_refs = ''
            selected_row.other_data.similar_requests.forEach((req, i) => {
                if (i) params.valuation_request_refs += ','
                if (req.hasOwnProperty('valuation_request_ref')) {
                    //Similar requests stored as objects
                    const ref = req.valuation_request_ref
                    params.valuation_request_refs += ref
                    dupl[ref] = req
                } else {
                    //Legacy for when similar requests were just strings
                    const ref = req.split(' ')[0]
                    //Save reasons as array for legacy requests too
                    const reasons = req.split(': ')[1].split(', ')
                    params.valuation_request_refs += ref
                    dupl[ref] = {
                        valuation_request_ref: ref,
                        reasons,
                    }
                }
            })
            params.load_all = 'true'
            axios.get(url, { params }).then((result) => {
                this.duplicatesData = result.data.map((row, index) => {
                    return {
                        ...dupl[row.valuation_request_ref],
                        ...row,
                        created_at: new Date(row.created_at),
                        index: index,
                        address: this.display_address(row),
                        deployment: this.isDispatchDashboard
                            ? row.valuation_request_ref.split('_')[0]
                            : null,
                        display_request_ref: this.isDispatchDashboard
                            ? row.valuation_request_ref.split('_')[1]
                            : null,
                        display_building_type: row.building_type
                            ? this.$t(`value.${row.building_type}`)
                            : '',
                        displayStatus: this.$t(`status.${row.status}`),
                        displayOwner: row.owner ? row.owner.email : '',
                        displayValuer: !this.isValuer
                            ? 'N/A'
                            : row.valuer.assigned
                            ? row.valuer.username
                            : 'N/A',
                    }
                })
                this.isDuplicatesLoading = false
            })
        },
        close_duplicates() {
            this.shownDuplicate = null
            this.isDuplicatesLoading = false
            this.duplicatesData = []
            this.currentDuplicatesPage = 1
        },
        /*view_details(row) {
            return axios
                .get(
                    `${utils.urlJoin(this.$config.VALUATION_API_URL, 'state')}?` +
                        `valuation_request_ref=${row.valuation_request_ref}` +
                        `&lang=${this.$route.params.lang}`
                )
                .then((response) => {
                    this.row_data = response.data.data
                    this.modal_open = true
                })
                .catch((error) => {
                    console.error(error)
                })
        },*/
    },
}
</script>

<style lang="scss">
.overflow-hidden {
    overflow: hidden;
}

.new_request-dropdown-item {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    color: #001837 !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}

.request_type_filter {
    display: flex;
    flex-direction: row;
    gap: 16px;

    .control-label {
        display: flex;
        align-items: center;
        gap: 4px;
        color: #001837;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
    }
}
.val_type_content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}

.tag > span {
    line-height: 25px;
}
.table-wrapper {
    min-height: 300px;
    position: relative;
}
.searchbox {
    min-width: 300px;
}
.clear-button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    color: grey;

    &:hover {
        color: black;
    }
}
.table {
    & > thead > tr:nth-child(2) .th-wrap > span {
        width: 100%;
    }
    & > tbody > tr > td {
        vertical-align: middle !important;
    }
    & .row_highlighted {
        background: #ffdd9fdd;

        &:hover {
            background: #ffdd9fff !important;
        }
    }

    .valuer-select-badge {
        height: 1.2rem;
        width: 1.2rem;
        border-radius: 50%;
    }
}
.duplicates-table {
    .nowrap {
        white-space: nowrap;
    }
}
.button-loading .icon {
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
}
@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
</style>
