import { render, staticRenderFns } from "./ExpertConfirmation.vue?vue&type=template&id=1aae4cc6&"
import script from "./ExpertConfirmation.vue?vue&type=script&lang=js&"
export * from "./ExpertConfirmation.vue?vue&type=script&lang=js&"
import style0 from "./ExpertConfirmation.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./ExpertConfirmation.vue?vue&type=custom&index=0&blockType=i18n&lang=json"
if (typeof block0 === 'function') block0(component)

export default component.exports