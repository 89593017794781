<template>
    <div class="container is-fluid section is-max-desktop">
        <div class="columns is-mobile is-multiline is-variable is-6">
            <template v-if="editing || !borrower">
                <div class="column is-12">
                    <h2
                        class="title is-size-3 has-text-weight-bold mgt-medium is-flex is-align-items-center pb-4"
                    >
                        What is the email address of the borrower?
                    </h2>
                    <p>
                        Rock.estate will send an email with a secured link. The recipient
                        can book an on-site expertise by clicking on this link.
                    </p>
                </div>
                <div class="column is-12">
                    <b-field grouped position="is-centered" custom-class="is-medium">
                        <b-input
                            v-model="email"
                            size="is-medium"
                            name="email"
                            expanded
                            placeholder="Borrower's email"
                            type="email"
                            autocorrect="off"
                            spellcheck="false"
                        ></b-input>
                        <p class="control is-flex is-justify-content-center">
                            <b-button
                                :disabled="!email"
                                size="is-medium "
                                label="Send"
                                type="is-primary"
                                @click="submit"
                            />
                        </p>
                        <p
                            v-if="editing"
                            class="control is-flex is-justify-content-center"
                        >
                            <b-button
                                size="is-medium "
                                label="Cancel"
                                type="is-dark"
                                @click="editing = false"
                            ></b-button>
                        </p>
                    </b-field>
                </div>
            </template>
            <template v-else>
                <div class="column is-12">
                    <h2
                        class="title is-size-3 has-text-weight-bold mgt-medium is-flex is-align-items-center pb-4"
                    >
                        The booking link is on its way!
                    </h2>
                    <p>
                        Rock.estate sent an email with a secured link to
                        {{ borrower.email }}. The recipient can book an on-site expertise
                        by clicking on this link.
                    </p>
                </div>
                <div class="column is-12 email-buttons">
                    <b-button
                        size="is-medium"
                        type="is-dark"
                        icon-left="arrow-left"
                        tag="router-link"
                        :to="{
                            params: { action: 'extra-info' },
                        }"
                    >
                        {{ $t('previous') }}
                    </b-button>
                    <b-button
                        size="is-medium"
                        type="is-dark"
                        icon-left="view-dashboard"
                        tag="router-link"
                        :to="{ name: 'dashboard' }"
                    >
                        {{ $t('monolith.apps.dashboard') }}
                    </b-button>
                    <b-button
                        size="is-medium"
                        type="is-primary"
                        icon-left="refresh"
                        @click="submit"
                    >
                        Send again
                    </b-button>
                    <b-button
                        size="is-medium"
                        type="is-primary"
                        icon-left="pencil"
                        @click="editing = true"
                    >
                        Edit email
                    </b-button>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import utils from '@/shared/plugins/utils'
import { mapGetters } from 'vuex'

export default {
    name: 'OVMSubmitter',
    data: () => ({
        email: '',
        editing: false,
    }),
    computed: {
        ...mapGetters('valuationStore', ['borrower']),
    },
    mounted() {
        if (this.borrower) {
            this.email = this.borrower.email
        }
    },
    methods: {
        async submit() {
            const res = await this.$axios.put(
                utils
                    .val_urls(this.$config)
                    .request_ref_borrower(
                        this.$store.state.valuationStore.valuation_request_ref
                    ),
                null,
                { params: { email: this.email } }
            )

            if (res.status === 200) {
                this.$store.commit('valuationStore/UPDATE_STATUS', {
                    borrower: {
                        email: this.email,
                    },
                })
                this.editing = false
            }
        },
    },
}
</script>

<style scoped lang="scss">
.email-buttons {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    .button {
        margin: 0.5rem;
    }
}
</style>
