<template>
    <div class="app_dashboard">
        <div class="app_dashboard-header">
            <h1>{{ $t('monolith.apps.welcome') }}{{ user ? ` ${user}` : '' }}</h1>
        </div>
        <div class="app_dashboard-content">
            <app-card v-if="accessibleModules.includes('dvm')" app-id="valuation" />
            <app-card v-if="accessibleModules.includes('ers')" app-id="ers" />
        </div>
    </div>
</template>
<script>
import utils from '@/shared/plugins/utils'
import { mapGetters } from 'vuex'
import AppCard from './AppCard.vue'

export default {
    name: 'AppDashboard',
    components: { AppCard },
    computed: {
        ...mapGetters('auth', ['getUsername', 'accessibleModules']),
        user() {
            return this.getUsername
                ? utils.user_name(this.getUsername).split(' ')[0]
                : null
        },
    },
}
</script>
<style lang="scss" scoped>
.app_dashboard {
    background: #f9fafc;

    &-content {
        padding: 40px 60px;
        display: flex;
        flex-direction: row;
        gap: 24px;
    }

    &-header {
        width: 100%;
        padding: 60px;
        min-height: 180px;
        background: linear-gradient(180deg, rgba(249, 250, 252, 0) 0%, #f9fafc 100%),
            url('../../assets/img/city.jpg') no-repeat,
            lightgray 0px -250px / 100% 356.25% no-repeat;
        background-size: cover;
        background-position: center right;

        h1 {
            color: #001837;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
        }
    }
}
</style>
