var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container is-fluid section is-max-desktop"},[_c('div',{staticClass:"columns is-mobile is-multiline is-variable is-6"},[_c('div',{staticClass:"column is-12"},[_c('h2',{staticClass:"title is-size-3 has-text-weight-bold mgt-medium is-flex is-align-items-center pb-4"},[_vm._v(" "+_vm._s(_vm.$t('title'))+" ")])])]),_c('div',[(_vm.show_parcel_area || _vm.show_touching_sides)?_c('div',{staticClass:"column is-11-desktop is-9-fullhd is-12-mobile is-12-tablet"},[_c('div',{staticClass:"columns is-multiline is-mobile is-variable is-6"},[(_vm.show_parcel_area)?_c('div',{staticClass:"column is-6-desktop is-12-mobile"},[_c('text-input',{attrs:{"disabled":!_vm.isDraft,"label":_vm.$t('features.f_parcel_area'),"tooltip":_vm.$t('features.f_parcel_area_tooltip'),"type":{
                            'is-danger':
                                !_vm.$v.structural.f_parcel_area.decimal ||
                                !_vm.$v.structural.f_parcel_area.required ||
                                ((!_vm.$v.structural.f_parcel_area.minValue ||
                                    !_vm.$v.structural.f_parcel_area.maxValue) &&
                                    _vm.$v.structural.f_parcel_area.decimal) ||
                                (_vm.is_incoherent('f_living_area') &&
                                    !_vm.$v.structural.f_parcel_area.$dirty),
                        },"message":[
                            (!_vm.$v.structural.f_parcel_area.minValue ||
                                !_vm.$v.structural.f_parcel_area.maxValue) &&
                            _vm.$v.structural.f_parcel_area.decimal
                                ? _vm.$t('valid_area', { min: 25, max: 99999 })
                                : null,
                            !_vm.$v.structural.f_parcel_area.decimal
                                ? _vm.$t('number_only')
                                : null,
                            !_vm.$v.structural.f_parcel_area.required
                                ? _vm.$t('field_required')
                                : null,
                            _vm.is_incoherent('f_parcel_area') &&
                            !_vm.$v.structural.f_parcel_area.$dirty
                                ? _vm.$t('incoherent_feature')
                                : null ]},on:{"input":function($event){return _vm.$v.structural.f_parcel_area.$touch()},"focus":function($event){return _vm.toggleFloorDiagram(false)}},model:{value:(_vm.structural.f_parcel_area),callback:function ($$v) {_vm.$set(_vm.structural, "f_parcel_area", $$v)},expression:"structural.f_parcel_area"}})],1):_vm._e(),(_vm.show_building_area)?_c('div',{staticClass:"column is-6-desktop is-12-mobile"},[_c('text-input',{attrs:{"disabled":!_vm.isDraft,"label":_vm.$t('features.f_building_area'),"tooltip":_vm.$t('features.f_building_area_tooltip'),"type":{
                            'is-danger':
                                !_vm.$v.structural.f_building_area.decimal ||
                                !_vm.$v.structural.f_building_area.required ||
                                ((!_vm.$v.structural.f_building_area.minValue ||
                                    !_vm.$v.structural.f_building_area.maxValue) &&
                                    _vm.$v.structural.f_building_area.decimal) ||
                                (_vm.is_incoherent('f_building_area') &&
                                    !_vm.$v.structural.f_building_area.$dirty),
                        },"message":[
                            (!_vm.$v.structural.f_building_area.minValue ||
                                !_vm.$v.structural.f_building_area.maxValue) &&
                            _vm.$v.structural.f_building_area.decimal
                                ? _vm.$t('valid_area', { min: 25, max: 99999 })
                                : null,
                            !_vm.$v.structural.f_building_area.decimal
                                ? _vm.$t('number_only')
                                : null,
                            !_vm.$v.structural.f_building_area.required
                                ? _vm.$t('field_required')
                                : null,
                            _vm.is_incoherent('f_building_area') &&
                            !_vm.$v.structural.f_building_area.$dirty
                                ? _vm.$t('incoherent_feature')
                                : null ]},on:{"input":function($event){return _vm.$v.structural.f_building_area.$touch()},"focus":function($event){return _vm.toggleFloorDiagram(false)}},model:{value:(_vm.structural.f_building_area),callback:function ($$v) {_vm.$set(_vm.structural, "f_building_area", $$v)},expression:"structural.f_building_area"}})],1):_vm._e(),(_vm.show_touching_sides)?_c('div',{staticClass:"column is-6-desktop is-12-mobile"},[_c('b-field',{attrs:{"custom-class":"is-small-tooltip"}},[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('features.f_number_of_facades_or_type'))+" "),_c('b-tooltip',{staticClass:"ml-2 mb-1",attrs:{"type":"is-primary","label":_vm.$t('features.f_number_of_facades_or_type_tooltip'),"multilined":""}},[_c('div',{staticClass:"is-rounded info has-text-primary has-background-link is-tootip-btn is-family-secondary has-text-weight-bold"},[_vm._v(" ? ")])])],1),_c('b-select',{attrs:{"placeholder":_vm.$t('common.select_one'),"disabled":!_vm.isDraft,"size":"is-medium","expanded":""},model:{value:(_vm.structural.touching_sides),callback:function ($$v) {_vm.$set(_vm.structural, "touching_sides", $$v)},expression:"structural.touching_sides"}},[_c('option',{domProps:{"value":2}},[_vm._v(" "+_vm._s(_vm.$t('value.attached'))+" ")]),_c('option',{domProps:{"value":3}},[_vm._v(" "+_vm._s(_vm.$t('value.semi'))+" ")]),_c('option',{domProps:{"value":4}},[_vm._v(" "+_vm._s(_vm.$t('value.detached'))+" ")])])],2)],1):_vm._e()])]):_vm._e(),(_vm.is_building)?_c('div',{staticClass:"column is-11-desktop is-9-fullhd is-12-mobile is-12-tablet"},[_c('div',{staticClass:"is-flex is-align-content-flex-end"},[_c('b-field',{staticClass:"is-w100",attrs:{"custom-class":"is-small-tooltip"}},[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.is_new ? _vm.$t('features.f_expected_epc') : _vm.$t('features.f_epc'))+" "),_c('b-tooltip',{attrs:{"type":"is-primary","label":_vm.is_new
                                    ? _vm.$t('features.f_expected_epc_tooltip')
                                    : _vm.$t('features.f_epc_tooltip'),"multilined":""}},[_c('div',{staticClass:"is-rounded info has-text-primary has-background-link is-tootip-btn is-family-secondary has-text-weight-bold"},[_vm._v(" ? ")])])],1),_c('b-radio-button',{attrs:{"native-value":true,"size":"is-medium","type":"is-primary","disabled":!_vm.isDraft},on:{"input":function () { return _vm.toggleFloorDiagram(false); }},model:{value:(_vm.structural.is_epc_number),callback:function ($$v) {_vm.$set(_vm.structural, "is_epc_number", $$v)},expression:"structural.is_epc_number"}},[_c('span',[_vm._v(_vm._s(_vm.$t('unit.kwh/m2year')))])]),_c('b-radio-button',{attrs:{"native-value":false,"size":"is-medium","type":"is-primary","disabled":!_vm.enableEpcLabel},on:{"input":function () { return _vm.toggleFloorDiagram(false); }},model:{value:(_vm.structural.is_epc_number),callback:function ($$v) {_vm.$set(_vm.structural, "is_epc_number", $$v)},expression:"structural.is_epc_number"}},[_c('span',[_vm._v("Label")])]),(_vm.structural.is_epc_number)?_c('b-field',{staticClass:"is-w100 ml-5",attrs:{"type":{
                            'is-danger':
                                !_vm.$v.structural.f_epc_numeric.decimal ||
                                !_vm.$v.structural.f_epc_numeric.required ||
                                !_vm.$v.structural.f_epc_numeric.maxValue ||
                                (_vm.is_incoherent('f_epc') &&
                                    !_vm.$v.structural.f_epc_numeric.$dirty),
                        },"message":[
                            !_vm.$v.structural.f_epc_numeric.decimal
                                ? _vm.$t('number_only')
                                : null,
                            !_vm.$v.structural.f_epc_numeric.maxValue
                                ? _vm.$t('value_too_large')
                                : null,
                            !_vm.$v.structural.f_epc_numeric.required
                                ? _vm.$t('field_required')
                                : null,
                            _vm.is_incoherent('f_epc') &&
                            !_vm.$v.structural.f_epc_numeric.$dirty
                                ? _vm.$t('incoherent_feature')
                                : null ]}},[_c('b-input',{attrs:{"size":"is-medium","disabled":!_vm.isDraft,"expanded":""},on:{"input":function($event){return _vm.$v.structural.f_epc_numeric.$touch()},"focus":function($event){return _vm.toggleFloorDiagram(false)}},model:{value:(_vm.structural.f_epc_numeric),callback:function ($$v) {_vm.$set(_vm.structural, "f_epc_numeric", $$v)},expression:"structural.f_epc_numeric"}})],1):_vm._e(),(_vm.structural.is_epc_number)?_c('span',{staticClass:"is-align-self-flex-start pt-3 ml-2 is-size-5 is-unit"},[_vm._v(" "+_vm._s(_vm.$t('unit.kwh/m2year'))+" ")]):_vm._e(),(!_vm.structural.is_epc_number)?_c('b-field',{staticClass:"is-w100 ml-5 ",attrs:{"type":{
                            'is-danger':
                                !_vm.$v.structural.f_epc_label.oneOf ||
                                _vm.is_incoherent('f_epc'),
                        },"message":[
                            !_vm.$v.structural.f_epc_label.oneOf ? _vm.$t('wrong_epc') : null,
                            _vm.is_incoherent('f_epc') ? _vm.$t('incoherent_feature') : null ]}},[_c('b-autocomplete',{attrs:{"size":"is-medium","placeholder":"B","open-on-focus":true,"data":_vm.epc_labels,"disabled":!_vm.isDraft},on:{"focus":function($event){return _vm.toggleFloorDiagram(false)}},model:{value:(_vm.structural.f_epc_label),callback:function ($$v) {_vm.$set(_vm.structural, "f_epc_label", $$v)},expression:"structural.f_epc_label"}})],1):_vm._e()],2)],1)]):_vm._e(),(_vm.is_building)?_c('div',{staticClass:"column is-11-desktop is-9-fullhd is-12-mobile is-12-tablet"},[_c('div',{staticClass:"columns is-multiline is-mobile is-variable is-6"},[_c('div',{staticClass:"column is-6-desktop is-12-mobile"},[_c('text-input',{attrs:{"disabled":!_vm.isDraft,"label":_vm.is_new
                                ? _vm.$t('features.f_expected_construction_year')
                                : _vm.$t('features.f_construction_year'),"tooltip":_vm.is_new
                                ? _vm.$t('features.f_expected_construction_year_tooltip')
                                : _vm.$t('features.f_construction_year_tooltip'),"type":{
                            'is-danger':
                                !_vm.$v.structural.f_construction_year.decimal ||
                                !_vm.$v.structural.f_construction_year.required ||
                                ((!_vm.$v.structural.f_construction_year.minValue ||
                                    !_vm.$v.structural.f_construction_year.maxValue) &&
                                    _vm.$v.structural.f_construction_year.decimal) ||
                                (_vm.is_incoherent('f_construction_year') &&
                                    !_vm.$v.structural.f_construction_year.$dirty),
                        },"message":[
                            (!_vm.$v.structural.f_construction_year.minValue ||
                                !_vm.$v.structural.f_construction_year.maxValue) &&
                            _vm.$v.structural.f_construction_year.decimal
                                ? _vm.$t('valid_years', { min: _vm.minYear, max: _vm.maxYear })
                                : null,
                            !_vm.$v.structural.f_construction_year.decimal
                                ? _vm.$t('number_only')
                                : null,
                            !_vm.$v.structural.f_construction_year.required
                                ? _vm.$t('field_required')
                                : null,
                            _vm.is_incoherent('f_construction_year') &&
                            !_vm.$v.structural.f_construction_year.$dirty
                                ? _vm.$t('incoherent_feature')
                                : null ]},on:{"input":function($event){return _vm.$v.structural.f_construction_year.$touch()},"focus":function($event){return _vm.toggleFloorDiagram(false)}},model:{value:(_vm.structural.f_construction_year),callback:function ($$v) {_vm.$set(_vm.structural, "f_construction_year", $$v)},expression:"structural.f_construction_year"}})],1),_c('div',{staticClass:"column is-6-desktop is-12-tablet is-flex is-align-content-flex-end"},[_c('text-input',{staticClass:"is-flex-grow-2",attrs:{"disabled":!_vm.isDraft,"label":_vm.is_new
                                ? _vm.$t('features.f_expected_living_area')
                                : _vm.$t('features.f_living_area'),"tooltip":_vm.is_new
                                ? _vm.$t('features.f_expected_living_area_tooltip')
                                : _vm.$t('features.f_living_area_tooltip'),"type":{
                            'is-danger':
                                !_vm.$v.structural.f_living_area.required ||
                                !_vm.$v.structural.f_living_area.maxValue ||
                                !_vm.$v.structural.f_living_area.decimal ||
                                (_vm.is_incoherent('f_living_area') &&
                                    !_vm.$v.structural.f_living_area.$dirty),
                        },"message":[
                            !_vm.$v.structural.f_living_area.decimal
                                ? _vm.$t('number_only')
                                : null,
                            !_vm.$v.structural.f_living_area.maxValue
                                ? _vm.$t('value_too_large')
                                : null,
                            !_vm.$v.structural.f_living_area.required
                                ? _vm.$t('field_required')
                                : null,
                            _vm.is_incoherent('f_living_area') &&
                            !_vm.$v.structural.f_living_area.$dirty
                                ? _vm.$t('incoherent_feature')
                                : null ]},on:{"focus":function($event){return _vm.toggleFloorDiagram(false)},"input":function($event){return _vm.$v.structural.f_living_area.$touch()}},model:{value:(_vm.structural.f_living_area),callback:function ($$v) {_vm.$set(_vm.structural, "f_living_area", $$v)},expression:"structural.f_living_area"}}),_c('span',{staticClass:"is-align-self-flex-start mt-5 pt-5 ml-2 is-size-5 is-unit"},[_vm._v(" "+_vm._s(_vm.$t('unit.m2'))+" ")])],1)])]):_vm._e(),(_vm.is_apartment)?_c('div',{staticClass:"column is-11-desktop is-9-fullhd is-12-mobile is-12-tablet"},[_c('div',{staticClass:"columns is-multiline is-mobile is-variable is-6"},[_c('div',{staticClass:"column is-6-desktop is-12-tablet"},[_c('int-selector',{attrs:{"disabled":!_vm.isDraft,"label":_vm.$t('features.f_bedrooms'),"tooltip":_vm.$t('features.f_bedrooms_tooltip'),"min":0,"max":20,"type":{
                            'is-danger':
                                _vm.is_incoherent('f_bedrooms') &&
                                !_vm.$v.apartment.f_bedrooms.$dirty,
                        },"message":[
                            _vm.is_incoherent('f_bedrooms') &&
                            !_vm.$v.apartment.f_bedrooms.$dirty
                                ? _vm.$t('incoherent_feature')
                                : null ]},on:{"focus":function($event){return _vm.toggleFloorDiagram(false)},"input":function () {
                                _vm.toggleFloorDiagram(false)
                                _vm.$v.apartment.f_bedrooms.$touch()
                            }},model:{value:(_vm.apartment.f_bedrooms),callback:function ($$v) {_vm.$set(_vm.apartment, "f_bedrooms", $$v)},expression:"apartment.f_bedrooms"}})],1),_c('div',{staticClass:"column is-6-desktop is-12-tablet"},[_c('radio-selector',{attrs:{"label":_vm.$t('features.f_balcony_present'),"disabled":!_vm.isDraft,"tooltip":_vm.$t('features.f_balcony_present_tooltip'),"options":[
                            {
                                label: _vm.$t('yes'),
                                value: true,
                            },
                            {
                                label: _vm.$t('no'),
                                value: false,
                            } ],"type":{
                            'is-danger':
                                _vm.is_incoherent('f_balcony_present') &&
                                !_vm.$v.apartment.f_balcony_present.$dirty,
                        },"message":[
                            _vm.is_incoherent('f_balcony_present') &&
                            !_vm.$v.apartment.f_balcony_present.$dirty
                                ? _vm.$t('incoherent_feature')
                                : null ]},on:{"input":function () {
                                _vm.toggleFloorDiagram(false)
                                _vm.$v.apartment.f_balcony_present.$touch()
                            }},model:{value:(_vm.apartment.f_balcony_present),callback:function ($$v) {_vm.$set(_vm.apartment, "f_balcony_present", $$v)},expression:"apartment.f_balcony_present"}})],1),_c('div',{class:("columns column is-mobile is-multiline is-12 is-floors-section " + (_vm.showFloorDiagram &&
                            _vm.isFloorDiagramEnabled &&
                            'diagram-is-shown'))},[_c('div',{staticClass:"column is-6-desktop is-12-tablet"},[_c('int-selector',{attrs:{"disabled":!_vm.isDraft,"label":_vm.$t('features.f_bottom_floor'),"tooltip":_vm.$t('features.f_bottom_floor_tooltip'),"min":-5,"max":0,"type":{
                                'is-danger':
                                    _vm.is_incoherent('f_bottom_floor') &&
                                    !_vm.$v.apartment.f_bottom_floor.$dirty,
                            },"message":[
                                _vm.is_incoherent('f_bottom_floor') &&
                                !_vm.$v.apartment.f_bottom_floor.$dirty
                                    ? _vm.$t('incoherent_feature')
                                    : null ]},on:{"focus":function($event){return _vm.toggleFloorDiagram(true)},"input":function () {
                                    _vm.$v.apartment.f_bottom_floor.$touch()
                                    _vm.toggleFloorDiagram(true)
                                }},model:{value:(_vm.apartment.f_bottom_floor),callback:function ($$v) {_vm.$set(_vm.apartment, "f_bottom_floor", $$v)},expression:"apartment.f_bottom_floor"}})],1),_c('div',{staticClass:"column is-6-desktop is-12-tablet"},[_c('int-selector',{attrs:{"disabled":!_vm.isDraft,"label":_vm.$t('features.f_top_floor'),"tooltip":_vm.$t('features.f_top_floor_tooltip'),"max":40,"type":{
                                'is-danger':
                                    _vm.is_incoherent('f_top_floor') &&
                                    !_vm.$v.apartment.f_top_floor.$dirty,
                            },"message":[
                                _vm.is_incoherent('f_top_floor') &&
                                !_vm.$v.apartment.f_top_floor.$dirty
                                    ? _vm.$t('incoherent_feature')
                                    : null ]},on:{"focus":function($event){return _vm.toggleFloorDiagram(true)},"input":function () {
                                    _vm.$v.apartment.f_top_floor.$touch()
                                    _vm.toggleFloorDiagram(true)
                                }},model:{value:(_vm.apartment.f_top_floor),callback:function ($$v) {_vm.$set(_vm.apartment, "f_top_floor", $$v)},expression:"apartment.f_top_floor"}})],1),_c('range-selector',{attrs:{"label":_vm.$t('apt_floors'),"tooltip":_vm.$t('features.f_floors_tooltip'),"disabled":!_vm.isDraft,"message":[
                            !_vm.$v.apartment.f_floors.consecutive
                                ? _vm.$t('floors_not_consecutive')
                                : null,
                            _vm.is_incoherent('f_floors') && !_vm.$v.apartment.f_floors.$dirty
                                ? _vm.$t('incoherent_feature')
                                : null ],"type":!_vm.$v.apartment.f_floors.consecutive ||
                            (_vm.is_incoherent('f_floors') &&
                                !_vm.$v.apartment.f_floors.$dirty)
                                ? 'is-danger'
                                : 'is-primary',"range-list":_vm.floor_list,"button-label-renderer":function (floor) { return (floor === 0 ? _vm.$t('ground_floor') : floor); }},on:{"input":function () {
                                _vm.$v.apartment.f_floors.$touch()
                                _vm.toggleFloorDiagram(true)
                            }},model:{value:(_vm.apartment.f_floors),callback:function ($$v) {_vm.$set(_vm.apartment, "f_floors", $$v)},expression:"apartment.f_floors"}})],1),_c('div',{staticClass:"column is-6-desktop is-12-tablet"},[_c('radio-selector',{attrs:{"label":_vm.$t('features.f_garden_common'),"disabled":!_vm.isDraft,"tooltip":_vm.$t('features.f_garden_common_tooltip'),"options":[
                            {
                                label: _vm.$t('yes'),
                                value: true,
                            },
                            {
                                label: _vm.$t('no'),
                                value: false,
                            } ],"type":{
                            'is-danger':
                                _vm.is_incoherent('f_garden_common') &&
                                !_vm.$v.apartment.f_garden_common.$dirty,
                        },"message":[
                            _vm.is_incoherent('f_garden_common') &&
                            !_vm.$v.apartment.f_garden_common.$dirty
                                ? _vm.$t('incoherent_feature')
                                : null ]},on:{"input":function () {
                                _vm.$v.apartment.f_garden_common.$touch()
                                _vm.toggleFloorDiagram(false)
                            }},model:{value:(_vm.apartment.f_garden_common),callback:function ($$v) {_vm.$set(_vm.apartment, "f_garden_common", $$v)},expression:"apartment.f_garden_common"}})],1),_c('div',{staticClass:"column is-6-desktop is-12-tablet"},[_c('radio-selector',{attrs:{"label":_vm.$t('features.f_garden_private'),"disabled":!_vm.isDraft,"tooltip":_vm.$t('features.f_garden_private_tooltip'),"options":[
                            {
                                label: _vm.$t('yes'),
                                value: true,
                            },
                            {
                                label: _vm.$t('no'),
                                value: false,
                            } ],"type":{
                            'is-danger':
                                _vm.is_incoherent('f_garden_private') &&
                                !_vm.$v.apartment.f_garden_private.$dirty,
                        },"message":[
                            _vm.is_incoherent('f_garden_private') &&
                            !_vm.$v.apartment.f_garden_private.$dirty
                                ? _vm.$t('incoherent_feature')
                                : null ]},on:{"input":function () {
                                _vm.$v.apartment.f_garden_private.$touch()
                                _vm.toggleFloorDiagram(false)
                            }},model:{value:(_vm.apartment.f_garden_private),callback:function ($$v) {_vm.$set(_vm.apartment, "f_garden_private", $$v)},expression:"apartment.f_garden_private"}})],1),_c('div',{staticClass:"column is-6-desktop is-12-tablet"},[_c('int-selector',{attrs:{"disabled":!_vm.isDraft,"tooltip":_vm.$t('features.f_n_closed_garage_tooltip'),"is-big":true,"label":_vm.$t('features.f_n_closed_garage'),"min":0,"max":20,"type":{
                            'is-danger':
                                _vm.is_incoherent('f_n_closed_garage') &&
                                !_vm.$v.apartment.f_n_closed_garage.$dirty,
                        },"message":[
                            _vm.is_incoherent('f_n_closed_garage') &&
                            !_vm.$v.apartment.f_n_closed_garage.$dirty
                                ? _vm.$t('incoherent_feature')
                                : null ]},on:{"input":function () {
                                _vm.$v.apartment.f_n_closed_garage.$touch()
                                _vm.toggleFloorDiagram(false)
                            },"focus":function($event){return _vm.toggleFloorDiagram(false)}},model:{value:(_vm.apartment.f_n_closed_garage),callback:function ($$v) {_vm.$set(_vm.apartment, "f_n_closed_garage", $$v)},expression:"apartment.f_n_closed_garage"}})],1),_c('div',{staticClass:"column is-6-desktop is-12-tablet"},[_c('int-selector',{attrs:{"disabled":!_vm.isDraft,"is-big":true,"label":_vm.$t('features.f_n_parking_spot'),"tooltip":_vm.$t('features.f_n_parking_spot_tooltip'),"min":0,"max":40,"type":{
                            'is-danger':
                                _vm.is_incoherent('f_n_parking_spot') &&
                                !_vm.$v.apartment.f_n_parking_spot.$dirty,
                        },"message":[
                            _vm.is_incoherent('f_n_parking_spot') &&
                            !_vm.$v.apartment.f_n_parking_spot.$dirty
                                ? _vm.$t('incoherent_feature')
                                : null ]},on:{"input":function () {
                                _vm.$v.apartment.f_n_parking_spot.$touch()
                                _vm.toggleFloorDiagram(false)
                            },"focus":function($event){return _vm.toggleFloorDiagram(false)}},model:{value:(_vm.apartment.f_n_parking_spot),callback:function ($$v) {_vm.$set(_vm.apartment, "f_n_parking_spot", $$v)},expression:"apartment.f_n_parking_spot"}})],1),_c('div',{staticClass:"column is-6-desktop is-12-tablet"},[_c('radio-selector',{attrs:{"label":_vm.$t('features.f_cellar_attic'),"disabled":!_vm.isDraft,"options":[
                            {
                                label: _vm.$t('yes'),
                                value: true,
                            },
                            {
                                label: _vm.$t('no'),
                                value: false,
                            } ],"type":{
                            'is-danger':
                                _vm.is_incoherent('f_cellar_attic') &&
                                !_vm.$v.apartment.f_cellar_attic.$dirty,
                        },"message":[
                            _vm.is_incoherent('f_cellar_attic') &&
                            !_vm.$v.apartment.f_cellar_attic.$dirty
                                ? _vm.$t('incoherent_feature')
                                : null ]},on:{"input":function () {
                                _vm.$v.apartment.f_cellar_attic.$touch()
                                _vm.toggleFloorDiagram(false)
                            }},model:{value:(_vm.apartment.f_cellar_attic),callback:function ($$v) {_vm.$set(_vm.apartment, "f_cellar_attic", $$v)},expression:"apartment.f_cellar_attic"}})],1)])]):_vm._e(),(_vm.allow_renovation)?_c('div',{staticClass:"column is-11-desktop is-9-fullhd is-12-mobile is-12-tablet"},[_c('div',{staticClass:"is-flex is-align-content-flex-end"},[_c('b-field',{attrs:{"custom-class":"is-small-tooltip"}},[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('section.renovation'))+" ")]),_c('b-radio-button',{attrs:{"native-value":false,"size":"is-medium","type":"is-primary","disabled":!_vm.isDraft},on:{"click":function($event){return _vm.$v.structural.renovation_cost.$touch()},"input":function($event){return _vm.toggleFloorDiagram(false)}},model:{value:(_vm.structural.has_renovation),callback:function ($$v) {_vm.$set(_vm.structural, "has_renovation", $$v)},expression:"structural.has_renovation"}},[_c('span',[_vm._v(_vm._s(_vm.$t('no')))])]),_c('b-radio-button',{attrs:{"disabled":!_vm.isDraft,"native-value":true,"size":"is-medium","type":"is-primary"},on:{"click":function($event){return _vm.$v.structural.renovation_cost.$touch()},"input":function($event){return _vm.toggleFloorDiagram(false)}},model:{value:(_vm.structural.has_renovation),callback:function ($$v) {_vm.$set(_vm.structural, "has_renovation", $$v)},expression:"structural.has_renovation"}},[_c('span',[_vm._v(_vm._s(_vm.$t('yes')))])])],2),(_vm.structural.has_renovation)?_c('b-field',{staticClass:"is-w100 ml-5",attrs:{"type":{
                        'is-danger':
                            !_vm.$v.structural.renovation_cost.decimal ||
                            !_vm.$v.structural.renovation_cost.required ||
                            !_vm.$v.structural.renovation_cost.minValue ||
                            (_vm.is_incoherent('renovation_cost') &&
                                !_vm.$v.structural.renovation_cost.$dirty) ||
                            _vm.renovation_cost_zero_invalid,
                    },"message":[
                        !_vm.$v.structural.renovation_cost.decimal
                            ? _vm.$t('number_only')
                            : null,

                        !_vm.$v.structural.renovation_cost.required
                            ? _vm.$t('field_required')
                            : null,
                        !_vm.$v.structural.renovation_cost.minValue
                            ? _vm.$t('renovation_cost_minValue')
                            : null,
                        _vm.renovation_cost_zero_invalid
                            ? _vm.$t('renovation_cost_zero_invalid')
                            : null,
                        _vm.is_incoherent('renovation_cost') &&
                        !_vm.$v.structural.renovation_cost.$dirty
                            ? _vm.$t('incoherent_feature')
                            : null ]}},[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('features.renovation_cost'))+" ")]),_c('b-input',{attrs:{"size":"is-medium","disabled":!_vm.isDraft,"expanded":""},on:{"input":function($event){return _vm.$v.structural.renovation_cost.$touch()},"focus":function($event){return _vm.toggleFloorDiagram(false)}},model:{value:(_vm.structural.renovation_cost),callback:function ($$v) {_vm.$set(_vm.structural, "renovation_cost", $$v)},expression:"structural.renovation_cost"}})],2):_vm._e(),(_vm.structural.has_renovation)?_c('span',{staticClass:"is-align-self-flex-start mt-5 pt-5 ml-2 mr-4 is-size-5 is-unit"},[_vm._v(" "+_vm._s(_vm.$t('unit.euro'))+" ")]):_vm._e(),(_vm.structural.has_renovation && _vm.allow_full_renovation)?_c('b-field',{attrs:{"message":[
                        _vm.$tc('renovation_info.items', _vm.renovation_items.length) +
                            ', ' +
                            _vm.$tc(
                                'renovation_info.linked_items',
                                _vm.renovation_items.filter(
                                    function (item) { return item.linked_document_ref !== null; }
                                ).length
                            ),
                        ((_vm.$t('common.total')) + ": " + (_vm.exact_value_formatter(
                            _vm.renovation_items
                                .map(function (item) { return item.amount; })
                                .reduce(function (a, b) { return a + b; }, 0)
                        ))) ]}},[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('renovation_info.title'))+" ")]),_c('b-button',{attrs:{"tag":"router-link","to":{
                            params: {
                                lang: this.$route.params.lang,
                                action: 'renovation-info',
                            },
                            query: this.$route.query,
                        },"size":"is-medium","type":"is-primary","icon-left":"plus"}},[_vm._v(" "+_vm._s(_vm.$t('renovation_info.title'))+" ")])],2):_vm._e()],1)]):_vm._e(),(_vm.is_new || _vm.is_plot)?_c('div',{staticClass:"column is-11-desktop is-9-fullhd is-12-mobile is-12-tablet"},[_c('div',{staticClass:"is-flex is-align-content-flex-end"},[(_vm.is_new)?_c('b-field',{staticClass:"is-w100",attrs:{"custom-class":"is-small-tooltip","type":{
                        'is-danger':
                            !_vm.$v.structural.construction_cost.decimal ||
                            !_vm.$v.structural.construction_cost.required ||
                            !_vm.$v.structural.construction_cost.minValue,
                    },"message":[
                        !_vm.$v.structural.construction_cost.decimal
                            ? _vm.$t('number_only')
                            : null,
                        !_vm.$v.structural.construction_cost.required
                            ? _vm.$t('field_required')
                            : null,
                        !_vm.$v.structural.construction_cost.minValue
                            ? _vm.$t('construction_cost_minValue')
                            : null ]}},[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('features.construction_cost'))+" "),_c('b-tooltip',{attrs:{"type":"is-primary","label":_vm.$t('features.construction_cost_tooltip'),"multilined":""}},[_c('div',{staticClass:"is-rounded info has-text-primary has-background-link is-tootip-btn is-family-secondary has-text-weight-bold"},[_vm._v(" ? ")])])],1),_c('b-input',{attrs:{"size":"is-medium","disabled":!_vm.isDraft,"expanded":""},on:{"input":function($event){return _vm.$v.structural.construction_cost.$touch()},"focus":function($event){return _vm.toggleFloorDiagram(false)}},model:{value:(_vm.structural.construction_cost),callback:function ($$v) {_vm.$set(_vm.structural, "construction_cost", $$v)},expression:"structural.construction_cost"}})],2):_vm._e(),(_vm.is_new)?_c('span',{staticClass:"is-align-self-flex-start mt-5 pt-5 ml-2 mr-4 is-size-5 is-unit"},[_vm._v(" "+_vm._s(_vm.$t('unit.euro'))+" ")]):_vm._e(),(_vm.is_new || _vm.is_plot)?_c('b-field',{staticClass:"is-w100",class:{ 'ml-5': _vm.is_new },attrs:{"custom-class":"is-small-tooltip","type":{
                        'is-danger':
                            !_vm.$v.structural.parcel_cost.decimal ||
                            !_vm.$v.structural.parcel_cost.required ||
                            !_vm.$v.structural.parcel_cost.minValue,
                    },"message":[
                        !_vm.$v.structural.parcel_cost.decimal ? _vm.$t('number_only') : null,
                        !_vm.$v.structural.parcel_cost.required
                            ? _vm.$t('field_required')
                            : null,
                        !_vm.$v.structural.parcel_cost.minValue
                            ? _vm.$t('parcel_cost_minValue')
                            : null ]}},[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('features.parcel_cost'))+" "),_c('b-tooltip',{attrs:{"type":"is-primary","label":_vm.$t('features.parcel_cost_tooltip'),"multilined":""}},[_c('div',{staticClass:"is-rounded info has-text-primary has-background-link is-tootip-btn is-family-secondary has-text-weight-bold"},[_vm._v(" ? ")])])],1),_c('b-input',{attrs:{"size":"is-medium","disabled":!_vm.isDraft,"expanded":""},on:{"input":function($event){return _vm.$v.structural.parcel_cost.$touch()},"focus":function($event){return _vm.toggleFloorDiagram(false)}},model:{value:(_vm.structural.parcel_cost),callback:function ($$v) {_vm.$set(_vm.structural, "parcel_cost", $$v)},expression:"structural.parcel_cost"}})],2):_vm._e(),((_vm.is_house && _vm.is_new) || _vm.is_plot)?_c('span',{staticClass:"is-align-self-flex-start mt-5 pt-5 ml-2 mr-4 is-size-5 is-unit"},[_vm._v(" "+_vm._s(_vm.$t('unit.euro'))+" ")]):_vm._e()],1)]):_vm._e()]),(
            _vm.isDraft ? _vm.document_purposes.length > 0 : Object.keys(_vm.documents).length > 0
        )?_c('div',[_c('div',{staticClass:"column is-12"},[_c('h2',{staticClass:"title is-size-3 has-text-weight-bold mgt-medium is-flex is-align-items-center pb-4"},[_vm._v(" "+_vm._s(_vm.$t('documents'))+" ")])]),(_vm.isDraft)?_c('div',{staticClass:"column is-11-desktop is-9-fullhd is-12-mobile is-12-tablet is-flex upload-row"},_vm._l((_vm.document_purposes),function(purpose){return _c('b-field',{key:'upload_field_' + purpose,staticClass:"is-flex-grow-1",attrs:{"type":{
                    'is-danger':
                        _vm.required_documents.includes(purpose) &&
                        _vm.documents_per_purpose[purpose].length == 0,
                },"message":[
                    _vm.required_documents.includes(purpose) &&
                    _vm.documents_per_purpose[purpose].length == 0
                        ? _vm.$t('required')
                        : null ]}},[_c('b-upload',{staticClass:"is-w100",attrs:{"type":"is-primary","rounded":"","outlined":"","accept":".jpg,.jpeg,.png,.pdf,image/png,image/jpeg,application/pdf","disabled":!_vm.isDraft},on:{"input":function($event){return _vm.upload_document(purpose)}},model:{value:(_vm.pending_document),callback:function ($$v) {_vm.pending_document=$$v},expression:"pending_document"}},[_c('a',{class:("button is-fullwidth is-outlined " + (_vm.required_documents.includes(purpose) &&
                                _vm.documents_per_purpose[purpose].length == 0
                                    ? 'is-danger'
                                    : 'is-primary'))},[_c('b-icon',{staticClass:"file-icon",attrs:{"icon":"plus"}}),_c('span',{staticClass:"file-label mr-1"},[_vm._v(" "+_vm._s(_vm.$t(("document." + purpose)))+" ")])],1)])],1)}),1):_vm._e(),_c('div',{staticClass:"column is-11-desktop is-9-fullhd is-12-mobile is-12-tablet mt-0 py-0"},_vm._l((_vm.document_purposes),function(purpose){return _c('div',{key:("documents_" + purpose)},_vm._l((_vm.documents_per_purpose[purpose]),function(document){return _c('div',{key:("doc_" + (document.document_ref)),staticClass:"upload-item"},[_c('img',{staticClass:"file-icon",attrs:{"src":require("@/assets/icons/ic-file.svg"),"alt":""}}),_c('a',{staticClass:"upload-name",attrs:{"href":_vm.documentURL(document.document_ref),"target":"_blank"}},[_c('span',{staticClass:"has-text-primary"},[_vm._v(" "+_vm._s(document.filename)+" ")])]),_c('span',[_vm._v(" "+_vm._s(_vm.$t(("document." + purpose)))+" ")]),(_vm.isDraft)?_c('b-button',{staticClass:"action-button is-light",attrs:{"rounded":""},on:{"click":function($event){return _vm.remove_document(document)}}},[_c('b-icon',{staticClass:"file-icon",attrs:{"icon":"delete"}})],1):_vm._e()],1)}),0)}),0)]):_vm._e(),_c('div',{staticClass:"column is-11-desktop is-9-fullhd is-12-mobile is-12-tablet mt-6"},[_c('div',{staticClass:"button-group"},[_c('b-button',{attrs:{"size":"is-medium","type":"is-dark","icon-left":"arrow-left"},on:{"click":_vm.prev_page}},[_vm._v(" "+_vm._s(_vm.$t('previous'))+" ")]),(
                    (_vm.isDraft || _vm.sending) &&
                        _vm.flow_type === 'avm_input_only' &&
                        _vm.$v.$anyDirty
                )?_c('b-button',{attrs:{"size":"is-medium","type":"is-dark","icon-left":"content-save","disabled":_vm.form_any_error,"loading":_vm.sending},on:{"click":function($event){return _vm.submitForm('save-draft', true)}}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")]):_vm._e(),((_vm.isDraft || _vm.sending) && _vm.flow_type === 'avm_input_only')?_c('b-button',{attrs:{"size":"is-medium","type":"is-primary","icon-right":"send","disabled":!_vm.form_complete,"loading":_vm.sending},on:{"click":function($event){return _vm.submitForm('submit')}}},[_vm._v(" "+_vm._s(_vm.$t('send'))+" ")]):_vm._e(),(!_vm.isDraft && !_vm.sending && _vm.flow_type === 'avm_input_only')?_c('b-button',{attrs:{"size":"is-medium","type":"is-dark","icon-left":"view-dashboard","tag":"router-link","to":{ name: 'dashboard' }}},[_vm._v(" "+_vm._s(_vm.$t('monolith.apps.dashboard'))+" ")]):_vm._e(),(_vm.flow_type === 'avm_demo')?_c('b-button',{attrs:{"size":"is-medium","type":"is-primary","icon-right":"arrow-right","disabled":!_vm.form_complete,"loading":_vm.sending},on:{"click":function($event){return _vm.submitForm('submit')}}},[_vm._v(" "+_vm._s(_vm.$t('next'))+" ")]):_vm._e(),(
                    !_vm.isDraft &&
                        !_vm.sending &&
                        _vm.getValuationType === 'dvm' &&
                        _vm.status === 'awaiting-onsite'
                )?_c('b-button',{attrs:{"size":"is-medium","type":"is-primary","disabled":!_vm.form_complete,"loading":_vm.sending},on:{"click":function($event){return _vm.submitForm('ovm')}}},[_vm._v(" Change into onsite request ")]):_vm._e(),(!_vm.isDraft && !_vm.sending && _vm.getValuationType === 'ovm')?_c('b-button',{attrs:{"size":"is-medium","type":"is-primary","icon-right":"arrow-right","disabled":!_vm.form_complete,"loading":_vm.sending,"tag":"router-link","to":{ params: { action: 'ovm' }, query: _vm.$route.query }}},[_vm._v(" "+_vm._s(_vm.$t('next'))+" ")]):_vm._e()],1)]),_c('b-modal',{attrs:{"has-modal-card":"","trap-focus":"","can-cancel":['escape'],"destroy-on-hide":false,"aria-role":"dialog","aria-modal":""},model:{value:(_vm.isExitingModalActive),callback:function ($$v) {_vm.isExitingModalActive=$$v},expression:"isExitingModalActive"}},[(_vm.isExitingModalActive)?_c('div',{staticClass:"exit-modal"},[_c('h2',{staticClass:"title is-size-4 has-text-weight-bold mgt-medium is-flex is-align-items-center pb-4"},[_vm._v(" "+_vm._s(_vm.$t('leave_modal.title'))+" ")]),_c('p',[_vm._v(_vm._s(_vm.$t('leave_modal.content')))]),_c('div',[(_vm.cancelFn)?_c('b-button',{attrs:{"size":"is-medium","type":"is-dark"},on:{"click":_vm.cancelFn}},[_vm._v(" "+_vm._s(_vm.$t('leave_modal.cancel'))+" ")]):_vm._e(),(_vm.proceedFn)?_c('b-button',{attrs:{"size":"is-medium","type":"is-primary"},on:{"click":function () {
                            _vm.submitForm('save-draft')
                            _vm.proceedFn()
                        }}},[_vm._v(" "+_vm._s(_vm.$t('leave_modal.save'))+" ")]):_vm._e(),(_vm.proceedFn)?_c('b-button',{attrs:{"size":"is-medium","type":"is-dark"},on:{"click":_vm.proceedFn}},[_vm._v(" "+_vm._s(_vm.$t('leave_modal.confirm'))+" ")]):_vm._e()],1)]):_vm._e()]),(_vm.isFloorDiagramEnabled)?_c('floors-diagram',{attrs:{"bottom-floor":_vm.apartment.f_bottom_floor,"top-floor":_vm.apartment.f_top_floor,"increase-bottom-floor":function () { return _vm.apartment.f_bottom_floor++; },"reduce-bottom-floor":function () { return _vm.apartment.f_bottom_floor--; },"increase-top-floor":function () { return _vm.apartment.f_top_floor++; },"reduce-top-floor":function () { return _vm.apartment.f_top_floor--; },"toggle-floor":_vm.toggleFloor,"activated-floors":_vm.apartment.f_floors,"building-floors":_vm.diagram_floor_list,"is-error":!_vm.$v.apartment.f_floors.consecutive,"show-diagram":_vm.showFloorDiagram,"error-message":_vm.$t('floors_not_consecutive')},on:{"close":function($event){return _vm.toggleFloorDiagram(false)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }