<i18n lang="json">
{
    "en-BE": {
        "borrower-details": "Borrower details",
        "appointment-pending": "Appointment",
        "payment-pending": "Payment",
        "confirmation-pending": "Expert confirmation",
        "valuation-pending": "Summary"
    }
}
</i18n>

<template>
    <div class="borrower-container">
        <div class="borrower-sidemenu">
            <div class="borrower-address">Address: {{ getAddress.full_address }}</div>
            <div v-for="s in steps" :key="s.step">
                <component
                    :is="stepTooSoon(s.step) ? 'span' : 'router-link'"
                    :class="{ disabled: stepTooSoon(s.step) }"
                    :to="{ query: { ...$route.query, step: s.step } }"
                >
                    {{ $t(s.step) }}
                </component>
            </div>
        </div>
        <div class="borrower-main">
            <component
                :is="currentStep.component"
                v-if="currentStep"
                :next-step="nextStep"
                :prev-step="prevStep"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BorrowerDetails from './BorrowerDetails.vue'
import AppointmentMaking from './AppointmentMaking.vue'
import Payment from './Payment.vue'
import ExpertConfirmation from './ExpertConfirmation.vue'
import AppointmentSummary from '../Summary.vue'

export default {
    name: 'BorrowerOVMRequest',
    data: () => ({
        steps: [
            {
                step: 'appointment-pending',
                component: AppointmentMaking,
            },
            {
                step: 'borrower-details',
                component: BorrowerDetails,
            },
            {
                step: 'payment-pending',
                component: Payment,
            },
            {
                step: 'confirmation-pending',
                component: ExpertConfirmation,
            },
            {
                step: 'valuation-pending',
                component: AppointmentSummary,
            },
        ],
    }),
    computed: {
        ...mapGetters('valuationStore', ['ovm_status']),
        ...mapGetters('valuationStore/Address', ['getAddress']),
        step() {
            return this.$route.query.step
        },
        stepsOrder() {
            const steps = []

            for (const step of this.steps) {
                steps.push(step.step)
            }

            return steps
        },
        currentStep() {
            return this.steps.find((s) => s.step === this.step)
        },
        nextStep() {
            const currentPos = this.stepsOrder.indexOf(this.step)
            if (currentPos === -1 || currentPos === this.stepsOrder.length - 1) {
                return null
            }
            const next = this.stepsOrder[currentPos + 1]

            return this.stepTooSoon(next) ? null : next
        },
        prevStep() {
            const currentPos = this.stepsOrder.indexOf(this.step)
            return this.stepsOrder[currentPos - 1] // No check needed as the previous step should always be available if it exists
        },
    },
    watch: {
        step(v) {
            this.stepHealthCheck(v)
        },
    },
    mounted() {
        this.stepHealthCheck(this.step)
    },
    methods: {
        isAllowedStep(step) {
            return this.stepsOrder.indexOf(step) !== -1
        },
        stepTooSoon(step) {
            return (
                this.stepsOrder.indexOf(step) > this.stepsOrder.indexOf(this.ovm_status)
            )
        },
        stepHealthCheck(step) {
            if (!step) {
                this.$router.replace({
                    query: {
                        ...this.$route.query,
                        step: this.isAllowedStep(this.ovm_status)
                            ? this.ovm_status
                            : this.stepsOrder[0],
                    },
                })
            } else {
                if (this.stepTooSoon(step)) {
                    this.$router.replace({
                        query: {
                            ...this.$route.query,
                            step: this.isAllowedStep(this.ovm_status)
                                ? this.ovm_status
                                : this.stepsOrder[0],
                        },
                    })
                }
            }
        },
    },
}
</script>

<style scoped lang="scss">
.borrower-container {
    display: grid;
    grid-template-columns: 235px auto;
    min-height: calc(100vh - 52px);
}

.borrower-address {
    font-weight: bold;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #d6e2e9;
}

.borrower-sidemenu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem;
    border-right: 1px solid #d6e2e9;

    .router-link-exact-active {
        font-weight: bold;
    }
}

.borrower-main {
    padding: 1rem;
}
</style>
