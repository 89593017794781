<template>
    <div class="container">
        <h2 class="title is-size-2 mgt-medium is-family-secondary has-text-weight-bold">
            Borrower details
        </h2>
        <form class="borrower-details" @submit.prevent="onSubmit">
            <b-loading :is-full-page="true" :active="userLoading" />
            <b-field grouped>
                <b-field label="First name" expanded>
                    <b-input v-model="first_name" required name="first_name" />
                </b-field>
                <b-field label="Last name" expanded>
                    <b-input v-model="last_name" required name="last_name" />
                </b-field>
            </b-field>
            <b-field label="Phone number">
                <b-input v-model="phone" required name="phone" />
            </b-field>
            <b-field label="Email">
                <b-input v-model="email" disabled name="email" />
            </b-field>
            <b-field label="Will you be present during the visit">
                <b-checkbox-button
                    v-model="willBePresent"
                    :native-value="true"
                    type="is-success"
                >
                    <b-icon icon="check"></b-icon>
                    <span>Yes</span>
                </b-checkbox-button>
                <b-checkbox-button
                    v-model="willBePresent"
                    :native-value="false"
                    type="is-danger"
                >
                    <b-icon icon="close"></b-icon>
                    <span>No</span>
                </b-checkbox-button>
            </b-field>
            <template v-if="!willBePresent">
                <div class="is-italic my-2">
                    Please ask a family, a friend or a neighbour to be on-site to give the
                    expert access. Please give us their name and phone number i case we
                    need to contact that person
                </div>
                <b-field grouped label="Representative details">
                    <b-field label="First name" expanded>
                        <b-input
                            v-model="representative_first_name"
                            required
                            name="representative_first_name"
                        />
                    </b-field>
                    <b-field label="Last name" expanded>
                        <b-input
                            v-model="representative_last_name"
                            required
                            name="representative_last_name"
                        />
                    </b-field>
                    <b-field label="Email" expanded>
                        <b-input
                            v-model="representative_email"
                            required
                            name="representative_email"
                        />
                    </b-field>
                </b-field>
            </template>
            <nav-buttons :next-step="nextStep" :prev-step="prevStep">
                <b-button
                    type="is-primary"
                    :class="isEdit ? '' : 'ml-auto'"
                    :icon-right="isEdit ? 'pencil' : 'send'"
                    native-type="submit"
                    :loading="loading"
                >
                    {{ isEdit ? 'Update' : 'Save' }}
                </b-button>
            </nav-buttons>
        </form>
    </div>
</template>

<script>
import utils from '@/shared/plugins/utils'
import { mapGetters, mapActions } from 'vuex'
import NavButtons from '../NavButtons.vue'

export default {
    name: 'BorrowerDetails',
    components: {
        NavButtons,
    },
    props: {
        nextStep: {
            required: false,
            default: '',
            type: String,
        },
        prevStep: {
            required: false,
            default: '',
            type: String,
        },
    },
    data: () => ({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        willBePresent: true,
        representative_first_name: '',
        representative_last_name: '',
        representative_email: '',
        loading: false,
        userLoading: true,
    }),
    computed: {
        ...mapGetters('valuationStore', ['borrower', 'getRequestRef', 'ovmInfo']),
        user_id() {
            return this.borrower.id
        },
        representative() {
            if (!this.willBePresent) {
                return {
                    first_name: this.representative_first_name,
                    last_name: this.representative_last_name,
                    email: this.representative_email,
                }
            } else {
                return null
            }
        },
        isEdit() {
            return this.ovmInfo && !utils.isEmptyObject(this.ovmInfo)
        },
    },
    mounted() {
        this.reset()
    },
    methods: {
        ...mapActions('valuationStore', ['load_valuation_request']),
        async loadUser() {
            this.userLoading = true
            const url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                'user',
                this.user_id,
            ])
            const res = await this.$axios.get(url)

            if (res.status === 200) {
                this.first_name = res.data.first_name
                this.last_name = res.data.last_name
                this.email = res.data.email
                this.phone = res.data.phone
            }
            this.userLoading = false
        },
        async onSubmit() {
            this.loading = true
            const url = utils.urlJoin(this.$config.VALUATION_API_URL, [
                'user',
                this.user_id,
            ])
            let res = await this.$axios.patch(url, {
                first_name: this.first_name,
                last_name: this.last_name,
                phone: this.phone,
            })

            if (res.status !== 200) {
                this.loading = false
                return
            }

            res = await this.$axios.patch(
                utils.val_urls(this.$config).request_ref(this.getRequestRef),
                {
                    ovm_info: {
                        will_be_present: this.willBePresent,
                        representative: this.representative,
                    },
                }
            )

            if (res.status !== 200) {
                this.loading = false
                return
            }

            this.loading = false
            if (!this.isEdit) {
                this.$router.push({
                    query: { ...this.$route.query, step: this.nextStep },
                })
            }
        },
        reset() {
            this.loadUser()
            this.willBePresent = this.ovmInfo?.will_be_present ?? true
            this.representative_first_name = this.ovmInfo?.representative.first_name ?? ''
            this.representative_last_name = this.ovmInfo?.representative.last_name ?? ''
            this.representative_email = this.ovmInfo?.representative.email ?? ''
        },
    },
}
</script>

<style>
.borrower-details {
    max-width: 520px;
    margin: auto;
}
.button-flex {
    display: flex;
    justify-content: space-between;
}
</style>
