<template>
    <div>
        <b-field label="Rule name">
            <b-input v-model="name" aria-placeholder="Rule name" />
        </b-field>
        <details class="region-field">
            <summary class="title is-size-5 mt-5">Regions</summary>
            <div v-for="reg in nuts3" :key="reg.prov_en">
                <h3 class="has-text-weight-semibold">{{ reg['prov' + lang] }}</h3>
                <div class="columns is-multiline is-gapless">
                    <div
                        v-for="n in reg.nuts3"
                        :key="n.NUTS_ID"
                        class="column is-one-third"
                    >
                        <b-field>
                            <b-checkbox
                                v-model="regions"
                                :native-value="n.NUTS_ID"
                                class="mt-2"
                            >
                                {{ n['arr' + lang] }}
                            </b-checkbox>
                        </b-field>
                    </div>
                </div>
            </div>
        </details>
        <div>
            <h3 class="title is-size-5 mt-5">Availability</h3>
            <cronofy-availability
                :rule-id="rule.id"
                :element-token="elementToken"
                @save="$emit('save', form)"
            />
        </div>
    </div>
</template>

<script>
import CronofyAvailability from './cronofyElements/CronofyAvailability'
export default {
    name: 'AvailabilityRule',
    components: {
        CronofyAvailability,
    },
    props: {
        rule: {
            type: Object,
            required: true,
        },
        nuts3: {
            type: Array,
            required: true,
        },
        elementToken: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        name: '',
        regions: [],
    }),
    computed: {
        lang() {
            if (this.$i18n.locale === 'fr-BE') return '_fr'
            if (this.$i18n.locale === 'nl-BE') return '_nl'
            return '_en'
        },
        form() {
            return {
                name: this.name,
                regions: this.regions,
            }
        },
    },
    mounted() {
        this.reset()
    },
    methods: {
        reset() {
            this.name = this.rule.name
            this.regions = this.rule.regions
        },
    },
}
</script>

<style lang="scss">
.region-field {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;

    summary {
        cursor: pointer;
    }
}
</style>
